import React, { useState } from 'react';
import cookie from 'react-cookies'
import { BrowserRouter, HashRouter, Route, Switch } from 'react-router-dom';
import SiteAdminLogin from './site-admin/SiteAdminLogin';
import SiteAdminHome from './site-admin/SiteAdminHome';

function App() {
  const [isLoggedIn, setLogin] = useState(cookie.load('Status', { doNotParse: true }) ?? false);
  function handleLogin(value) {
    cookie.save('Status', JSON.parse(value).status, {
      path: '/',
      secure: false
    })
    cookie.save('UserID', JSON.parse(value).userID, {
      path: '/',
      secure: false
    })
    cookie.save('Email', JSON.parse(value).email, {
      path: '/',
      secure: false
    })
    setLogin(JSON.parse(value).status);
    window.location.reload();
  }
  function handleLogout(value) {
    cookie.remove('Status', { path: '/' })
    cookie.remove('UserID', { path: '/' })
    cookie.remove('Status', { path: '/' })
    setLogin(JSON.parse(value).status);
    window.location.reload();
  }

  return (
    <BrowserRouter>
      <div>
        <Switch>
          <Route path="/" component={SiteAdminLogin} exact />
          <Route path="/home" component={SiteAdminHome} />
          <Route component={SiteAdminLogin} />
        </Switch>
      </div>
    </BrowserRouter>
  );

  // return <h5>Software under maintenance. Kindly check back later.</h5>
  // return isLoggedIn ?<HomeScreen handleLogout={handleLogout}/> : <LoginScreen handleLogin={handleLogin}/>;
}

export default App;
