import React, { useState, useEffect, useRef } from 'react';
import CustomLabel from "../components/CustomLabel";
import CustomAlert from '../components/CustomAlert';
import CustomButton from '../components/CustomButton';
import { Form } from 'react-bootstrap';
import cookie from 'react-cookies';
import CustomCard from '../components/CustomCard';
import CustomVehicleDropDownCMT from "../components/CustomVehicleDropDownCMT";
import CustomDivider from '../components/CustomDivider';
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import CustomTextInput from '../components/CustomTextInput';


const ModifyOrderCMTScreen = props => {

     console.log(props);
     const [remarks, setRemarks] = useState(props.editElement.remarks);
     const reload = false;
     const [userInfo, setUserInfo] = useState({ username: '', mobile: '', email: '', password: '', userLevel: 2, name: '' });
     const [userDetails, setUserDetails] = useState(null);
     const [loading, setLoading] = useState(false);
     const [department, setDepartmentList] = useState([]);
     const [selectedDepartment, setSelectedDepartment] = useState(null);
     const [vehicleList, setVehicleList] = useState([]);
     const [height, setHeight] = useState(0);
     const [alertInfo, setAlertInfo] = useState({ visibility: false, type: 'danger', message: '' });

     const [vehicles, setVehicles] = useState([]);

     function Timer(time, message, type) {
          setAlertInfo({ visibility: true, type: type, message: message });
          setInterval(() => {
               setAlertInfo({ visibility: false, type: type, message: '' });
          }, time);
     }

     function handleOrder(e) {
          if (e) { // add?
               e.preventDefault();
               //      e.persist();
          }
          if (remarks == props.editElement.remarks) {
               Timer(5000, 'Cannot sent remarks. Kindly verify!', false);
          }
          else {
               setLoading(true);
               const requestOptions = {
                    mode: 'cors',
                    method: 'POST',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Origin': 'http://smfal.speedotrack.com/' },
                    body: new URLSearchParams({
                         orderID: props.editElement._id,
                         remarks: remarks, orderStatus: '4', userID: (cookie.load('UserID', { doNotParse: true }) ?? '')
                    })
               };
               fetch('http://smfal.speedotrack.com/api/update-order-remarks', requestOptions)
                    .then(function (response) {
                         return response.json();
                    }).then(function (result) {
                         console.log(result);
                         if (result.success === true) {
                              setLoading(false);
                              Timer(7000, `Review sent to client.`, result.success);
                         }
                         else {
                              setLoading(false);
                              Timer(7000, result.message, result.success);
                         }
                    }).catch(function (params) {
                         setLoading(false);
                         console.log(params);
                         Timer(7000, ' ' + params, false);
                    });
          }
     }

     function fetchAllDepartment() {
          const requestOptions = {
               mode: 'cors',
               method: 'POST',
               headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Origin': 'http://smfal.speedotrack.com/' },
               body: new URLSearchParams({ userID: (cookie.load('UserID', { doNotParse: true }) ?? '') })
          };
          fetch('http://smfal.speedotrack.com/api/department', requestOptions)
               .then(function (response) {
                    if (response.statusText === 'Unauthorized') {
                         return 'Unauthorized';
                    }
                    else {
                         return response.json();
                    }
               }).then(function (result) {

                    const deviceModelList = [];
                    result.forEach(element => {
                         deviceModelList.push({ label: element.name, value: element._id });
                    });
                    setDepartmentList(deviceModelList);
               }).catch(function (params) {
                    setDepartmentList([]);
                    Timer(7000, ' ' + params, false);
               });
     }

     function fetchUserDetails() {
          const requestOptions = {
               mode: 'cors',
               method: 'POST',
               headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Origin': 'http://smfal.speedotrack.com/' },
               body: new URLSearchParams({ orderUserID: props.editElement.user_id })
          };
          fetch('http://smfal.speedotrack.com/api/user-details', requestOptions)
               .then(function (response) {
                    if (response.statusText === 'Unauthorized') {
                         return 'Unauthorized';
                    }
                    else {
                         return response.json();
                    }
               }).then(function (result) {
                    setUserDetails(result);
               }).catch(function (params) {

                    Timer(7000, ' ' + params, false);
               });
     }

     useEffect(() => {
          setHeight(myRef.current.clientWidth * 0.275);
          fetchUserDetails();
          fetchAllDepartment();
     }, [reload]);
     const myRef = useRef();



     let status = '';
     if (props.editElement.orderStatus == '1') {
          status = 'Pending';
     }
     else if (props.editElement.orderStatus == '2') {
          let s = true;
          for (let index = 0; index < props.editElement.vehicleList.length; index++) {
               const elementV = props.editElement.vehicleList[index];
               if (!elementV.isVehicle) {
                    s = false;
                    break;
               }
          }
          if (s) {
               status = 'Completed';
          } else {
               status = 'P. Deployed';
          }
     }
     else if (props.editElement.orderStatus == '3') {
          status = 'Modification';
     }
     else if (props.editElement.orderStatus == '4') {
          status = 'Remarks';
     }
     else if (props.editElement.orderStatus == '5') {
          status = 'Replacement';
     }

     return <div ref={myRef} style={{ width: '100%', height: '100%', backgroundColor: '#F4F5F7', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

          <CustomCard style={{ width: '95%', height: '95%' }}>
               <div style={{ width: '100%', justifyContent: 'space-between', display: 'flex' }}>
                    <CustomLabel text={`Order ID - ${props.editElement.orderShownID}`} />
                    <CustomLabel text={`Order Date - ${props.editElement.order_date}`} />
               </div>
               <div style={{ width: '100%', justifyContent: 'space-between', display: 'flex' }}>
                    <CustomLabel text={`Client Name - ${userDetails ? userDetails.name : ''}`} />
                    <CustomLabel text={`Client Department - ${userDetails ? userDetails.department : ''}`} />
               </div>
               <CustomDivider />
               <CustomLabel text={`Order Status: ${status}`} />
               <div style={{ marginTop: 5, paddingLeft: 5, width: '100%', justifyContent: 'space-between', display: 'flex', backgroundColor: 'silver' }}>
                    <label style={{ flex: 2, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>Vehicle Req.</label>
                    <label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>Assigned</label>
                    <label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>Assigned Vehicle</label>
               </div>
               <div style={{ overflow: 'auto', height: `${height}px` }}>
                    {props.editElement.vehicleList.map((element) => {
                         return <div style={{ paddingLeft: 5, width: '100%', justifyContent: 'space-between', display: 'flex', backgroundColor: 2 % 2 == 0 ? '#F4F5F7' : 'white' }}>
                              <label style={{ flex: 2, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}><div><label>{element.vehicleCategoryName}</label>
                                   <br />
                                   <div style={{ marginTop: 5, paddingLeft: 5, width: '100%', justifyContent: 'space-between', display: 'flex', backgroundColor: '#F6F6F6' }}>
                                        <label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>Start Time</label>
                                        <label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>End Time</label></div>
                                   <div style={{ paddingLeft: 5, width: '100%', justifyContent: 'space-between', display: 'flex', backgroundColor: 'white' }}>
                                        <label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>{element.start}</label>
                                        <label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>{element.end}</label></div>
                              </div>
                              </label>
                              <label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>{element.isVehicle ? 'Yes' : 'No'}</label>
                              <label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>{element.vehicleNumber} {element.breakdown ? '(Broken Vehicle)' : ''}</label>
                         </div>;
                    })}
               </div>
               <div style={{
                    flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '55px', width: '100%',
               }} >
                    <div style={{ width: '80%' }} >
                         <CustomTextInput placeholder={'Enter Remarks'} value={remarks} onTextChange={setRemarks} inputType={'text'} />
                    </div>
                    <div style={{ width: '10px' }} />
                    <div style={{ width: '20%', marginTop: '1.20%', }} >
                         <CustomButton style={{ width: '100%' }} onClick={handleOrder} text={'Send remarks'} loading={false} />
                    </div>
               </div>

          </CustomCard>
          <CustomAlert info={alertInfo} />
     </div >
}

export default ModifyOrderCMTScreen;