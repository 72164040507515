import React, { useState, useEffect } from 'react';
import CustomLabel from "../components/CustomLabel";
import CustomAlert from '../components/CustomAlert';
import CustomButton from '../components/CustomButton';
import { Form } from 'react-bootstrap';
import cookie from 'react-cookies';
import CustomCard from '../components/CustomCard';
import CustomReplacementDropDown from "../components/CustomReplacementDropDown";
import CustomDivider from '../components/CustomDivider';
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";


const ReplacementOrderScreen = props => {
     const reload = false;
     const [userInfo, setUserInfo] = useState({ username: '', mobile: '', email: '', password: '', userLevel: 2, name: '' });
     const [loading, setLoading] = useState(false);

     const [vehicleList, setVehicleList] = useState([]);

     const [alertInfo, setAlertInfo] = useState({ visibility: false, type: 'danger', message: '' });

     function Timer(time, message, type) {
          setAlertInfo({ visibility: true, type: type, message: message });
          setInterval(() => {
               setAlertInfo({ visibility: false, type: type, message: '' });
          }, time);
     }

     function handleOrder(e) {
          if (e) { // add?
               e.preventDefault();
               //      e.persist();
          }
          setLoading(true);
          const requestOptions = {
               mode: 'cors',
               method: 'POST',
               headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Origin': 'http://smfal.speedotrack.com/' },
               body: new URLSearchParams({ vehicles: JSON.stringify(vehicleList), orderID: props.editElement._id, orderStatus: '1', userID: (cookie.load('UserID', { doNotParse: true }) ?? '') })
          };
          fetch('http://smfal.speedotrack.com/api/update-order', requestOptions)
               .then(function (response) {
                    return response.json();
               }).then(function (result) {
                    if (result.success === true) {
                         setLoading(false);
                         Timer(7000, `Order modified. Wait for review.`, result.success);
                    }
                    else {
                         setLoading(false);
                         Timer(7000, result.message, result.success);
                    }
               }).catch(function (params) {
                    setLoading(false);
                    Timer(7000, ' ' + params, false);
               });
     }

     useEffect(() => {
          setVehicleList(props.editElement.vehicleList);
     }, [reload]);

     return <div style={{ width: '100%', height: '100%', backgroundColor: '#F4F5F7', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

          <CustomCard>
               <CustomLabel text={`Replacement Form`} />
               <CustomDivider />
               <Form style={{ color: 'black', textAlign: 'start', marginBottom: '10px' }}>
                    <Form.Label style={{ fontSize: '16px', letterSpacing: 0.75, textAlign: 'start', fontWeight: 'bold', width: '100%', marginBottom: '5px', fontFamily: 'serif' }}>Order Date: {props.editElement.order_date}</Form.Label>
                    <CustomDivider />
                    {vehicleList.map((element, index) => {
                         return new Date().getTime() > new Date(element.start).getTime() && new Date().getTime() < new Date(element.end).getTime() ? <div><Form.Label style={{ fontSize: '14px', letterSpacing: 0.75, textAlign: 'start', fontWeight: 'bold' }}>Ask replacement for {element.vehicleCategoryName} - {element.vehicleNumber}</Form.Label>
                         <CustomReplacementDropDown label={`Replacement Required     `} handleChange={(selectedOption) => {
                              setVehicleList((currentList) => {
                                   currentList.splice(index, 1);
                                   let list = [...currentList, {
                                        id: currentList.length,
                                        start: element.start,
                                        end: element.end,
                                        vehicleCategoryName: selectedOption.label,
                                        vehicleCategoryValue: selectedOption.value,
                                   }];
                                   return list;
                              });
                         }} onDelete={() => {
                              let list = vehicleList;
                              list = list.splice(index, 1);
                              setVehicleList(list);
                         }} vehicleData={element} /></div> : <div>
                              <Form.Label style={{ fontSize: '14px', letterSpacing: 0.75, textAlign: 'start', fontWeight: 'bold' }}>{element.vehicleCategoryName} - {element.vehicleNumber}</Form.Label>
                              <br />
                              <Form.Label style={{ fontSize: '14px', letterSpacing: 0.75, textAlign: 'start', }}>Start Time: {element.start}</Form.Label>
                              <br />
                              <Form.Label style={{ fontSize: '14px', letterSpacing: 0.75, textAlign: 'start', }}>End Time: {element.end}</Form.Label>
                              <br />
                              <Form.Label style={{ fontSize: '14px', letterSpacing: 0.75, textAlign: 'start', }}>------------------</Form.Label>
                         </div>
                    })}
                    <CustomButton onClick={handleOrder} text={'Send replacement request'} loading={loading} />
               </Form>
          </CustomCard>
          <CustomAlert info={alertInfo} />
     </div >
}

export default ReplacementOrderScreen;