import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaEdit, FaSearchLocation, FaTrashAlt } from 'react-icons/fa';
import { Form } from 'react-bootstrap';
import cookie from 'react-cookies';

const CustomDepartmentDropDown = ({ vehicleData, label, handleChange, onDelete }) => {
     const [vehicleCategory, setVehicleCategory] = useState([]);
     const reload = false;
     const [alertInfo, setAlertInfo] = useState({ visibility: false, type: 'danger', message: '' });

     function Timer(time, message, type) {
          setAlertInfo({ visibility: true, type: type, message: message });
          setInterval(() => {
               setAlertInfo({ visibility: false, type: type, message: '' });
          }, time);
     }

     function fetchAllDevicesModel() {
          const requestOptions = {
               mode: 'cors',
               method: 'POST',
               headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Origin': 'http://smfal.speedotrack.com/' },
               body: new URLSearchParams({ userID: (cookie.load('UserID', { doNotParse: true }) ?? '') })
          };
          fetch('http://smfal.speedotrack.com/api/vehicle-category', requestOptions)
               .then(function (response) {
                    if (response.statusText === 'Unauthorized') {
                         return 'Unauthorized';
                    }
                    else {
                         return response.json();
                    }
               }).then(function (result) {

                    const deviceModelList = [];
                    result.forEach(element => {
                         deviceModelList.push({ label: element.name, value: element._id });
                    });
                    setVehicleCategory(deviceModelList);
               }).catch(function (params) {
                    setVehicleCategory([]);
                    Timer(7000, ' ' + params, false);
               });
     }

     useEffect(() => {
          fetchAllDevicesModel();
     }, [reload]);

     return (
          <div style={{ flex: 2,display:'flex',alignItems:'center'}}>
               <div style={{ width: '80%'}}>
               <Select onChange={handleChange} options={vehicleCategory} style={{ overflow: 'scroll' }} placeholder={label.split(' or')[0]} />
               </div>
          </div>
     );
}

export default CustomDepartmentDropDown;