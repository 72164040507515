import React, { useState } from "react";
import { parse, subMonths, addMonths, format, startOfWeek, startOfMonth, endOfMonth, endOfWeek, isSameMonth, isSameDay, addDays } from "date-fns";
import CustomLabel from "../components/CustomLabel";
import './Calendar.css';

const Calendar = ({ allOrders, onDateSelect }) => {
     const [currentMonth, setCurrentMonth] = useState(new Date());
     const [selectedDate, setSelectedDate] = useState(new Date());

     const dateFormat = "MMMM yyyy";


     const renderDays = () => {
          const dateFormat = "EEEE";
          const days = [];

          let startDate = startOfWeek(currentMonth);

          for (let i = 0; i < 7; i++) {
               days.push(
                    <div className="col col-center" key={i}>
                         {format(addDays(startDate, i), dateFormat)}
                    </div>
               );
          }

          return <div className="days row">{days}</div>;
     }

     const renderCells = () => {
          const monthStart = startOfMonth(currentMonth);
          const monthEnd = endOfMonth(monthStart);
          const startDate = startOfWeek(monthStart);
          const endDate = endOfWeek(monthEnd);

          const dateFormat = "d";
          const rows = [];

          let days = [];
          let day = startDate;
          let formattedDate = "";

          while (day <= endDate) {
               for (let i = 0; i < 7; i++) {
                    formattedDate = format(day, dateFormat);
                    const cloneDay = day;
                    let booking = 0;
                    let deployed = 0;
                    for (let index = 0; index < allOrders.length; index++) {
                         const element = allOrders[index];
                         if (new Date(element.vehicleList.start).getDate() == formattedDate) {
                              if (!element.vehicleList.isVehicle) {
                                   booking++;
                              }
                              else{
                                   deployed++;
                              }
                         }
                    }
                    days.push(
                         <div
                              className={`col cell ${!isSameMonth(day, monthStart)
                                   ? "disabled"
                                   : isSameDay(day, selectedDate) ? "selected" : ""
                                   }`}
                              key={day}
                              onClick={() => {
                                   onDateClick(new Date(cloneDay))
                              }}>
                              <label style={{ position: 'absolute', bottom: '0', right: 0, backgroundColor: isSameDay(day, selectedDate) ? '#B061D0' : '#A3A3A3', color: 'white', fontSize: '12px', paddingLeft: '5px', paddingRight: '5px', fontFamily: 'serif', borderRadius: '5px', margin: '2px' }}>{booking} B - {deployed} D</label>
                              <span className="number">{formattedDate}</span>
                              <span className="bg">{formattedDate}</span>
                         </div>
                    );
                    day = addDays(day, 1);
               }
               rows.push(
                    <div className="row" key={day}>
                         {days}
                    </div>
               );
               days = [];
          }
          return <div className="body">{rows}</div>;
     }

     const onDateClick = day => {
          console.log(day);
          setSelectedDate(day);
          onDateSelect(day);
     };

     const nextMonth = () => {
          setCurrentMonth(addMonths(currentMonth, 1));
     };

     const prevMonth = () => {
          setCurrentMonth(subMonths(currentMonth, 1));
     };

     const renderHeader = () => {

          return (
               <div className="header row flex-middle">
                    <div className="col col-start">
                         <div className="icon" onClick={prevMonth}>
                              chevron_left
                         </div>
                    </div>
                    <div className="col col-center">
                         <span>{format(currentMonth, dateFormat)}</span>
                    </div>
                    <div className="col col-end" onClick={nextMonth}>
                         <div className="icon">chevron_right</div>
                    </div>
               </div>
          );
     }

     return (
          <div className="calendar">
               {renderHeader()}
               {renderDays()}
               {renderCells()}
          </div>
     );

}


export default Calendar;