import React, { useState, useEffect } from 'react';
import CustomLabel from "../components/CustomLabel";
import CustomCountryPicker from "../components/CustomCountryPicker";
import CustomAlert from '../components/CustomAlert';
import CustomButton from '../components/CustomButton';
import CustomTextInput from '../components/CustomTextInput';
import { Form } from 'react-bootstrap';
import cookie from 'react-cookies';
import CustomCard from '../components/CustomCard';
import CustomDropDown from "../components/CustomDropDown";
import CustomDivider from '../components/CustomDivider';
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";


const CreateAccountScreen = props => {
     const reload = false;
     const [userInfo, setUserInfo] = useState({ username: '', mobile: '', email: '', password: '', userLevel: 2, name: '' });
     const [loading, setLoading] = useState(false);
     const [department, setDepartmentList] = useState([]);
     const [selectedDepartment, setSelectedDepartment] = useState(null);
     const [usersList, setUsersList] = useState([]);
     const [alertInfo, setAlertInfo] = useState({ visibility: false, type: 'danger', message: '' });

     function Timer(time, message, type) {
          setAlertInfo({ visibility: true, type: type, message: message });
          setInterval(() => {
               setAlertInfo({ visibility: false, type: type, message: '' });
          }, time);
     }

     function handleCreateAccount(e) {
          console.log(userInfo);
          if (e) { // add?
               e.preventDefault();
               //      e.persist();
          }
          if (!userInfo.username || !userInfo.email || !userInfo.password) {
               Timer(5000, 'Kindly fill all the fields.', false);
          }
          else {
               setLoading(true);
               const requestOptions = {
                    mode: 'cors',
                    method: 'POST',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Origin': 'http://smfal.speedotrack.com/' },
                    body: new URLSearchParams({
                         username: userInfo.username,
                         mobile: userInfo.mobile, email: userInfo.email, name: userInfo.name, password: userInfo.password, createdby: (cookie.load('UserID', { doNotParse: true }) ?? ''),
                         departmentID: selectedDepartment, userLevel: userInfo.userLevel
                    })
               };
               fetch('http://smfal.speedotrack.com/api/register', requestOptions)
                    .then(function (response) {
                         if (response.statusText === 'Unauthorized') {
                              return 'Unauthorized';
                         }
                         else {
                              return response.json();
                         }
                    }).then(function (result) {
                         if (result.success === true) {
                              setLoading(false);
                              Timer(7000, `User created successfully for ${userInfo.email}`, result.success);
                              setUserInfo({ username: '', mobile: '', email: '', password: '', userLevel: 2, name: '' });
                              fetchAllUsers();
                         }
                         else {
                              setLoading(false);
                              Timer(7000, result.message, result.success);
                         }
                    }).catch(function (params) {
                         setLoading(false);
                         Timer(7000, ' ' + params, false);
                    });
          }
     }

     function fetchAllDepartment() {
          const requestOptions = {
               mode: 'cors',
               method: 'POST',
               headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Origin': 'http://smfal.speedotrack.com/' },
               body: new URLSearchParams({ userID: (cookie.load('UserID', { doNotParse: true }) ?? '') })
          };
          fetch('http://smfal.speedotrack.com/api/department', requestOptions)
               .then(function (response) {
                    if (response.statusText === 'Unauthorized') {
                         return 'Unauthorized';
                    }
                    else {
                         return response.json();
                    }
               }).then(function (result) {

                    const deviceModelList = [];
                    result.forEach(element => {
                         deviceModelList.push({ label: element.name, value: element._id });
                    });
                    setDepartmentList(deviceModelList);
               }).catch(function (params) {
                    setDepartmentList([]);
                    Timer(7000, ' ' + params, false);
               });
     }

     function fetchAllUsers() {
          const requestOptions = {
               mode: 'cors',
               method: 'POST',
               headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Origin': 'http://smfal.speedotrack.com/' },
               body: new URLSearchParams({ userID: (cookie.load('UserID', { doNotParse: true }) ?? '') })
          };
          fetch('http://smfal.speedotrack.com/api/all-users', requestOptions)
               .then(function (response) {
                    if (response.statusText === 'Unauthorized') {
                         return 'Unauthorized';
                    }
                    else {
                         return response.json();
                    }
               }).then(function (result) {
                    console.log(result);
                    let users = [];
                    for (let index = 0; index < result.length; index++) {
                         const element = result[index];
                         users.push(element.name + ' - ' + element.department + '@@   ' + element.mobile + ' - ' + element.email);
                    }
                    setUsersList(users);
               }).catch(function (params) {
                    console.log(params);
                    setUsersList([]);
                    Timer(7000, ' ' + params, false);
               });
     }

     useEffect(() => {
          fetchAllDepartment();
          fetchAllUsers();
     }, [reload]);

     return <div style={{ width: '100%', height: '100%', backgroundColor: '#F4F5F7', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

          <CustomCard>
               <CustomLabel text={'Create an account.'} />
               <CustomDivider />
               <Form style={{ color: 'black', textAlign: 'start', marginBottom: '10px' }}>
                    <CustomTextInput placeholder={'Enter Full Name'} value={userInfo.name} onTextChange={(value) => { setUserInfo((latestUserInfo) => { return { ...latestUserInfo, name: value } }); }} inputType={'name'} />
                    <CustomCountryPicker placeholder={'Enter Mobile Number'} value={userInfo.mobile} onTextChange={(value) => { setUserInfo((latestUserInfo) => { return { ...latestUserInfo, mobile: value } }); }} >
                         Enter Mobile Number
                    </CustomCountryPicker>
                    <CustomTextInput placeholder={'Enter Email'} value={userInfo.email} onTextChange={(value) => { setUserInfo((latestUserInfo) => { return { ...latestUserInfo, email: value, username: value } }); }} inputType={'email'} />
                    <CustomTextInput placeholder={'Enter Password'} value={userInfo.password} onTextChange={(value) => { setUserInfo((latestUserInfo) => { return { ...latestUserInfo, password: value } }); }} inputType={'password'} />
                    <RadioGroup aria-label="anonymous" name="anonymous" value={userInfo.userLevel == '1' ? 'Admin' : 'User'} row style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }} onChange={(event) => { setUserInfo((latestUserInfo) => { return { ...latestUserInfo, userLevel: event.target.value === 'Admin' ? '1' : '2' } }); }}>
                         <FormControlLabel style={{ fontSize: '14px', letterSpacing: 0.75, color: 'grey', textAlign: 'start', marginBottom: '5px' }} value="Admin" control={<Radio />} label="Admin" />
                         <FormControlLabel style={{ fontSize: '14px', letterSpacing: 0.75, color: 'grey', textAlign: 'start', marginBottom: '5px' }} value="User" control={<Radio />} label="User" />
                    </RadioGroup>
                    <CustomDropDown label={'Department'} handleChange={(selectedOption) => {
                         setSelectedDepartment(selectedOption.value);
                    }} list={department} />
                    <CustomButton onClick={handleCreateAccount} text={'Create Account'} loading={loading} />
               </Form>
          </CustomCard>
          <div style={{ width: '2.5%' }} />
          <CustomCard>
               <CustomLabel text={'All Users'} />
               <CustomDivider />
               {usersList.map((element, index) => {
                    return <div><label>{index + 1}. {element.split('@@')[0]}</label><br /><label>{element.split('@@')[1]}</label></div>
               })}
          </CustomCard>
          <CustomAlert info={alertInfo} />
     </div >
}

export default CreateAccountScreen;