import React, { useState } from 'react';
import { FaMobileAlt } from 'react-icons/fa';
import './Sidebar.css';
import { FiLogOut } from 'react-icons/fi';
import cookie from 'react-cookies'

const Sidebar = ({props, isExpanded, handleToggler, menu, onItemClick, selectedKey, widthState }) => {
     return (<div className={isExpanded ? "Sidebar" : "Sidebar collapsed"}>
          <div className="sidebar-items" onMouseEnter={handleToggler} onMouseLeave={handleToggler}>
               {menu.map((screen) => <SideMenuElement selectedKey={selectedKey} name={screen.name} click={onItemClick} id={screen.key} icon={screen.icon} />)}
          </div>
          <div className="sidebar-items" onMouseEnter={handleToggler} onMouseLeave={handleToggler}>
               <div onClick={() => {
                    cookie.remove('Status');
                    cookie.remove('UserID');
                    cookie.remove('Email')
                    cookie.remove('Admin');
                    cookie.remove('AdminLevel');
                    props.history.replace('/');
               }} className={"itemSel"}>
                    <FiLogOut className="sidebar-icon" />
                    <span className="sidebar-text">{'Logout'}</span>
               </div>
          </div>
     </div>);
}

const SideMenuElement = ({ selectedKey, name, click, id, icon }) => {
     return <div onClick={() => { click(id) }} className={selectedKey === id ? "itemSel" : "item"}>
          {icon}
          <span className="sidebar-text">{name}</span>
     </div>;
}

export default Sidebar;