import React, { useEffect, useState } from "react";
import cookie from 'react-cookies';
import { Form } from "react-bootstrap";
import CustomLabel from "../components/CustomLabel";
import CustomTextInput from "../components/CustomTextInput";
import CustomButton from "../components/CustomButton";
import CustomCardWidth from "../components/CustomCardWidth";
import CustomDivider from "../components/CustomDivider";
import CustomDropDown from "../components/CustomDropDown";
import CustomAlert from "../components/CustomAlert";
const axios = require('axios').default;

const CreateVehicleScreen = props => {
     const [loading, setLoading] = useState(false);
     const [vehicleCategory, setVehicleCategory] = useState([]);
     const [vehicleList, setVehicleList] = useState([]);
     const [deviceInfo, setDeviceInfo] = useState({
          companyName: '', model: '', number: '', categoryID: ''
     });
     const [alertInfo, setAlertInfo] = useState({ visibility: false, type: 'danger', message: '' });

     function Timer(time, message, type) {
          setAlertInfo({ visibility: true, type: type, message: message });
          setInterval(() => {
               setAlertInfo({ visibility: false, type: type, message: '' });
          }, time);
     }
     const reload = false;

     const createVehicle = (e) => {
          if (e) { // add?
               e.preventDefault();
               //      e.persist();
          }
          setLoading(true);
          axios.post("http://www.speedotrack.in/func/fn_connect_custom.php", new URLSearchParams({
               cmd: 'login', mobile: 'false',
               password: '123456',
               remember_me: 'true',
               username: 'smfal'
          }), {
               withCredentials: true,
               credentials: 'same-origin'
          }).then(result => {
               axios.post('http://www.speedotrack.in/func/fn_settings_custom.objects.php', new URLSearchParams({
                    cmd: 'load_object_data', user_id: result.data.user_id
               }), {
                    withCredentials: true,
                    credentials: 'same-origin'
               }).then(result => {
                    for (let i = 0; i < Object.keys(result.data).length; i++) {
                         console.log(result.data[Object.keys(result.data)[i]]);
                         const vehicle = {
                              companyName: result.data[Object.keys(result.data)[i]][4], model: Object.keys(result.data)[i], number: result.data[Object.keys(result.data)[i]][14], categoryID: ''
                         };
                         const userID = (cookie.load('UserID', { doNotParse: true }) ?? '')
                         const requestOptions = {
                              mode: 'cors',
                              method: 'POST',
                              headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Origin': 'http://smfal.speedotrack.com/' },
                              body: new URLSearchParams({ ...vehicle, userID: userID })
                         };
                         fetch('http://smfal.speedotrack.com/api/create-vehicle', requestOptions)
                              .then(function (response) {
                                   if (response.statusText === 'Unauthorized') {
                                        return 'Unauthorized';
                                   }
                                   else {
                                        return response.json();
                                   }
                              }).then(async function (result) {
                                   if (result.success === true) {
                                        setLoading(false);
                                        Timer(7000, `Vehicle created successfully.`, result.success);
                                   }
                                   else {
                                        setLoading(false);
                                        Timer(7000, result.message, result.success);
                                   }
                              }).catch(function (params) {
                                   setLoading(false);
                                   Timer(7000, ' ' + params, false);
                              });
                    }
               }).catch(params => {
                    Timer(7000, ' ' + params, false);
               })
          }).catch(function (params) {
               Timer(7000, ' ' + params);
          });
     }

     function createGroup(e) {
          if (e) { // add?
               e.preventDefault();
               //      e.persist();
          }
          setLoading(true);
          axios.post("http://www.speedotrack.in/func/fn_connect_custom.php", new URLSearchParams({
               cmd: 'login', mobile: 'false',
               password: '123456',
               remember_me: 'true',
               username: 'smfal'
          }), {
               withCredentials: true,
               credentials: 'same-origin'
          }).then(result => {
               axios.post('http://www.speedotrack.in/func/fn_settings_custom.groups.php', new URLSearchParams({
                    cmd: 'load_object_group_data', user_id: result.data.user_id
               }), {
                    withCredentials: true,
                    credentials: 'same-origin'
               }).then(result => {
                    for (let i = 0; i < Object.keys(result.data).length; i++) {
                         console.log(result.data[Object.keys(result.data)[i]]);
                         const vehicle = {
                              department: result.data[Object.keys(result.data)[i]].name, dID: Object.keys(result.data)[i],
                         };
                         const userID = (cookie.load('UserID', { doNotParse: true }) ?? '')
                         const requestOptions = {
                              mode: 'cors',
                              method: 'POST',
                              headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Origin': 'http://smfal.speedotrack.com/' },
                              body: new URLSearchParams({ ...vehicle, userID: userID })
                         };
                         fetch('http://smfal.speedotrack.com/api/create-department', requestOptions)
                              .then(function (response) {
                                   if (response.statusText === 'Unauthorized') {
                                        return 'Unauthorized';
                                   }
                                   else {
                                        return response.json();
                                   }
                              }).then(async function (result) {
                                   if (result.success === true) {
                                        setLoading(false);
                                        Timer(7000, `Vehicle created successfully.`, result.success);
                                   }
                                   else {
                                        setLoading(false);
                                        Timer(7000, result.message, result.success);
                                   }
                              }).catch(function (params) {
                                   setLoading(false);
                                   Timer(7000, ' ' + params, false);
                              });
                    }
               }).catch(params => {
                    Timer(7000, ' ' + params, false);
               })
          }).catch(function (params) {
               Timer(7000, ' ' + params);
          });
     }



     function handleImportDevice(e) {
          setLoading(true);
          const userID = (cookie.load('UserID', { doNotParse: true }) ?? '')
          const requestOptions = {
               mode: 'cors',
               method: 'POST',
               headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Origin': 'http://smfal.speedotrack.com/' },
               body: new URLSearchParams({ ...deviceInfo, userID: userID })
          };
          fetch('http://smfal.speedotrack.com/api/create-vehicle', requestOptions)
               .then(function (response) {
                    if (response.statusText === 'Unauthorized') {
                         return 'Unauthorized';
                    }
                    else {
                         return response.json();
                    }
               }).then(async function (result) {
                    if (result.success === true) {
                         setLoading(false);
                         Timer(7000, `Vehicle created successfully.`, result.success);
                         setDeviceInfo({
                              companyName: '', model: '', number: '', categoryID: ''
                         });
                    }
                    else {
                         setLoading(false);
                         Timer(7000, result.message, result.success);
                    }
               }).catch(function (params) {
                    setLoading(false);
                    Timer(7000, ' ' + params, false);
               });
     }

     function fetchAllDevicesModel() {
          const requestOptions = {
               mode: 'cors',
               method: 'POST',
               headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Origin': 'http://smfal.speedotrack.com/' },
               body: new URLSearchParams({ userID: (cookie.load('UserID', { doNotParse: true }) ?? '') })
          };
          fetch('http://smfal.speedotrack.com/api/vehicle-category', requestOptions)
               .then(function (response) {
                    if (response.statusText === 'Unauthorized') {
                         return 'Unauthorized';
                    }
                    else {
                         return response.json();
                    }
               }).then(function (result) {

                    const deviceModelList = [];
                    result.forEach(element => {
                         deviceModelList.push({ label: element.name, value: element._id });
                    });
                    setVehicleCategory(deviceModelList);
               }).catch(function (params) {
                    setVehicleCategory([]);
                    Timer(7000, ' ' + params, false);
               });
     }

     function fetchAllDepartment() {
          const requestOptions = {
               mode: 'cors',
               method: 'POST',
               headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Origin': 'http://smfal.speedotrack.com/' },
               body: new URLSearchParams({ userID: (cookie.load('UserID', { doNotParse: true }) ?? '') })
          };
          fetch('http://smfal.speedotrack.com/api/all-vehicles', requestOptions)
               .then(function (response) {
                    if (response.statusText === 'Unauthorized') {
                         return 'Unauthorized';
                    }
                    else {
                         return response.json();
                    }
               }).then(function (result) {
                    let list = [<div style={{ paddingLeft: 5, width: '100%', justifyContent: 'space-between', display: 'flex', backgroundColor: 'silver' }}><label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>Model</label><label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>ID</label><label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>IMEI</label><label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>Category</label></div>];
                    for (let index = 0; index < result.length; index++) {
                         const element = result[index];
                         let name = <div style={{ paddingLeft: 5, width: '100%', justifyContent: 'space-between', display: 'flex', backgroundColor: index % 2 == 0 ? '#F4F5F7' : 'white' }}><label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>{element.company}</label><label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>{element.number}</label><label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>{element.model}</label><label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>{element.categoryID}</label></div>
                         list.push(name);
                    }
                    setVehicleList(list);
               }).catch(function (params) {
                    setVehicleList([]);
               });
     }

     useEffect(() => {
          fetchAllDepartment();
          fetchAllDevicesModel();
     }, [reload]);

     return <div style={{ width: '100%', height: '100%', backgroundColor: '#F4F5F7', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CustomCardWidth style={{ width: '30%' }}>
               <div style={{ flexDirection: 'column', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }} >
                    <CustomLabel text={'Sync with Speedotrack Servers!'} />
                    <CustomButton style={{ width: '30%' }} onClick={createVehicle} text={'Sync Vehicle'}
                         loading={loading} />
                    <div style={{ marginBottom: '5px' }} />
                    <CustomButton style={{ width: '30%' }} onClick={createGroup} text={'Sync Department/Groups'}
                         loading={loading} />
                    <div style={{ marginBottom: '5px' }} />
                    {/* <label style={{ fontFamily: 'monospace', fontSize: '10px', color: 'black', textAlign: 'start', marginBottom: '10px' }}>*Using this multiple times will create duplicate data.</label> */}
               </div>
          </CustomCardWidth>
          <CustomAlert info={alertInfo} />
     </div>
}

export default CreateVehicleScreen;