import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaEdit, FaSearchLocation, FaTrashAlt } from 'react-icons/fa';
import { Form } from 'react-bootstrap';
import cookie from 'react-cookies';
import CustomTextInput from "../components/CustomTextInput";
import CustomDatePicker from "../components/CustomDatePicker";

const CustomReplacementDropDown = ({ vehicleData, label, handleChange, onDelete }) => {
     const [vehicleCategory, setVehicleCategory] = useState([]);
     const reload = false;
     const [alertInfo, setAlertInfo] = useState({ visibility: false, type: 'danger', message: '' });
     const [startDate, setStartDate] = useState(new Date());
     const [endDate, setEndDate] = useState(new Date(vehicleData.end));
     const [modelInfo, setModelInfo] = useState('');

     function Timer(time, message, type) {
          setAlertInfo({ visibility: true, type: type, message: message });
          setInterval(() => {
               setAlertInfo({ visibility: false, type: type, message: '' });
          }, time);
     }

     function fetchAllDevicesModel() {
          const requestOptions = {
               mode: 'cors',
               method: 'POST',
               headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Origin': 'http://smfal.speedotrack.com/' },
               body: new URLSearchParams({ userID: (cookie.load('UserID', { doNotParse: true }) ?? '') })
          };
          fetch('http://smfal.speedotrack.com/api/vehicle-category', requestOptions)
               .then(function (response) {
                    if (response.statusText === 'Unauthorized') {
                         return 'Unauthorized';
                    }
                    else {
                         return response.json();
                    }
               }).then(function (result) {

                    const deviceModelList = [];
                    result.forEach(element => {
                         deviceModelList.push({ label: element.name, value: element._id });
                    });
                    setVehicleCategory(deviceModelList);
               }).catch(function (params) {
                    setVehicleCategory([]);
                    Timer(7000, ' ' + params, false);
               });
     }

     useEffect(() => {
          fetchAllDevicesModel();
     }, [reload]);

     return (
          <div style={{ width: '100%' }}>
               <Form.Group className="form-control" className="mb-3" controlId="formBasicPassword">
                    <Form.Label style={{ fontSize: '14px', letterSpacing: 0.75, color: 'grey', textAlign: 'start', marginBottom: '5px' }}>Start Time: {vehicleData.start} </Form.Label>
                    <br />
                    <Form.Label style={{ fontSize: '14px', letterSpacing: 0.75, color: 'grey', textAlign: 'start', marginBottom: '5px' }}>End Time: {vehicleData.end} </Form.Label>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                         <CustomDatePicker minDate={new Date()} placeholder={'Enter New Start Time'} onTextChange={setStartDate} value={startDate} />
                         <CustomDatePicker minDate={new Date(startDate)} placeholder={'Enter New End Time'} onTextChange={setEndDate} value={endDate} />
                    </div>
                    <CustomTextInput placeholder={'Enter replacement reason'} value={modelInfo} onTextChange={setModelInfo} inputType={'text'} />

                    <Select onChange={handleChange} options={vehicleCategory} style={{ overflow: 'scroll' }} placeholder={'Select Vehicle Category'} />
               </Form.Group>
          </div>
     );
}

export default CustomReplacementDropDown;