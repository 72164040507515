import React, { useEffect, useState, useRef } from "react";
import cookie from 'react-cookies';
import { Form } from "react-bootstrap";
import CustomLabel from "../components/CustomLabel";
import CustomTextInput from "../components/CustomTextInput";
import CustomAlert from "../components/CustomAlert";
import CustomButton from "../components/CustomButton";
import CustomCard from "../components/CustomCard";
import CustomDivider from "../components/CustomDivider";
import CustomDropDown from "../components/CustomDropDown";
import CustomDatePicker from "../components/CustomDatePicker";
import dateFormat from 'dateformat';
import CustomQuantitySelector from "../components/CustomQuantitySelector";
import ViewAllOrdersScreen from "./ViewAllOrdersScreen";

const CreateOrderScreen = props => {
     const [alertInfo, setAlertInfo] = useState({ visibility: false, type: 'danger', message: '' });

     function Timer(time, message, type) {
          setAlertInfo({ visibility: true, type: type, message: message });
          setInterval(() => {
               setAlertInfo({ visibility: false, type: type, message: '' });
          }, time);
     }
     const [loading, setLoading] = useState(false);
     const reload = false;
     const [vehicleCategory, setVehicleCategory] = useState([]);
     const [startDate, setStartDate] = useState(new Date());
     const [endDate, setEndDate] = useState(new Date());
     const [itemList, setItemList] = useState([]);
     const [currentValue, setCurrentValue] = useState(1);
     const [statusValue, setStatusValue] = useState(null);
     const [remarks, setRemarks] = useState('');

     function handleNewOrder(e, vehicleList, remarks) {
          if (e) { // add?
               e.preventDefault();
          }
          setLoading(true);
          const requestOptions = {
               mode: 'cors',
               method: 'POST',
               headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Origin': 'http://smfal.speedotrack.com/' },
               body: new URLSearchParams({ vehicles: JSON.stringify(vehicleList), remarks: remarks, orderTime: dateFormat(new Date(), "yyyy-mm-dd HH:MM:ss"), userID: (cookie.load('UserID', { doNotParse: true }) ?? '') })
          };
          fetch('http://smfal.speedotrack.com/api/create-order', requestOptions)
               .then(function (response) {
                    if (response.statusText === 'Unauthorized') {
                         return 'Unauthorized';
                    }
                    else {
                         return response.json();
                    }
               }).then(function (result) {
                    if (result.success === true) {
                         setLoading(false);
                         Timer(7000, `Order created successfully. Kindly wait for review.`, result.success);
                         props.onOrderSuccess(<ViewAllOrdersScreen />);
                    }
                    else {
                         setLoading(false);
                         Timer(7000, result.message, result.success);
                    }
               }).catch(function (params) {
                    setLoading(false);
                    Timer(7000, ' ' + params, false);
               });
     }


     function fetchAllDevicesModel() {
          const requestOptions = {
               mode: 'cors',
               method: 'POST',
               headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Origin': 'http://smfal.speedotrack.com/' },
               body: new URLSearchParams({ userID: (cookie.load('UserID', { doNotParse: true }) ?? '') })
          };
          fetch('http://smfal.speedotrack.com/api/vehicle-category', requestOptions)
               .then(function (response) {
                    if (response.statusText === 'Unauthorized') {
                         return 'Unauthorized';
                    }
                    else {
                         return response.json();
                    }
               }).then(function (result) {

                    const deviceModelList = [];
                    result.forEach(element => {
                         deviceModelList.push({ label: element.name, value: element._id });
                    });
                    setVehicleCategory(deviceModelList);
               }).catch(function (params) {
                    setVehicleCategory([]);
                    Timer(7000, ' ' + params, false);
               });
     }


     function fetchAllVehicles(vehicleCategoryValue, start, end, cList) {
          setStatusValue(0);
          const requestOptions = {
               mode: 'cors',
               method: 'POST',
               headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Origin': 'http://smfal.speedotrack.com/' },
               body: new URLSearchParams({ vehicleCatID: vehicleCategoryValue, userID: (cookie.load('UserID', { doNotParse: true }) ?? '') })
          };
          fetch('http://smfal.speedotrack.com/api/vehicles', requestOptions)
               .then(function (response) {
                    if (response.statusText === 'Unauthorized') {
                         return 'Unauthorized';
                    }
                    else {
                         return response.json();
                    }
               }).then(function (result) {
                    let list = [];
                    for (let index = 0; index < result.length; index++) {
                         const element = result[index];
                         let name = element.number;
                         if (element.booking.length > 0) {
                              let shouldAdd = false;
                              for (let index = 0; index < element.booking.length; index++) {
                                   const item = element.booking[index];
                                   console.log(start);
                                   console.log(end);
                                   console.log(start);
                                   console.log(start);
                                   if ((Date.parse(start) >= Date.parse(item.start) && Date.parse(start) <= Date.parse(item.end)) || (Date.parse(end) >= Date.parse(item.start) && Date.parse(end) <= Date.parse(item.end))) {
                                        shouldAdd = false;
                                        break;
                                   }
                                   else {
                                        shouldAdd = true;
                                   }
                              }
                              if (shouldAdd) {
                                   list.push({ value: element._id, categoryID: element.categoryID, label: name, booking: element.booking });
                              }
                         }
                         else {
                              list.push({ value: element._id, categoryID: element.categoryID, label: name, booking: element.booking });
                         }
                    }
                    if (cList.length > 0) {
                         for (let i = 0; i < cList.length; i++) {
                              const element = cList[i];
                              if (element.vehicleCategoryValue == vehicleCategoryValue) {
                                   if (!(Date.parse(start) >= Date.parse(element.start) && Date.parse(element.end) <= Date.parse(start) && Date.parse(end) >= Date.parse(element.start) && Date.parse(element.end) <= Date.parse(end))) {
                                        if (list.length >= element.quantity) {
                                             list = list.splice(element.quantity);
                                        }
                                   }

                              }
                         }
                    }
                    setStatusValue(list.length);
                    setCurrentValue(1);

               }).catch(function (params) {
                    setStatusValue(0);
               });
     }

     useEffect(() => {
          fetchAllDevicesModel();
          setHeight(myRef.current.clientWidth * 0.21);
     }, [reload]);

     const [height, setHeight] = useState(0);
     const myRef = useRef();



     const [selectedVehicleCategory, setSelectedVehicleCategory] = useState(null);
     return <div ref={myRef} style={{ width: '100%', height: '100%', backgroundColor: '#F4F5F7', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CustomCard style={{ width: '90%', height: '100%', position: 'relative', }}>
               <CustomLabel text={'Create a new order.'} />
               <CustomDivider />
               <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '55px' }} >
                    <div style={{ width: '20%' }} >
                         <CustomDropDown label={'Vehicle Type'} handleChange={(selectedOption) => {
                              fetchAllVehicles(selectedOption.value, startDate, endDate, itemList);
                              setSelectedVehicleCategory(selectedOption);
                         }} list={vehicleCategory} />
                    </div>
                    <div style={{ width: '10px' }} />
                    <div style={{ width: '20%' }} >
                         <CustomDatePicker minDate={new Date()} placeholder={'Enter Start Time'} onTextChange={(value) => {
                              fetchAllVehicles(selectedVehicleCategory.value, value, endDate, itemList);
                              setStartDate(value);
                         }} value={startDate} />
                    </div>
                    <div style={{ width: '10px' }} />
                    <div style={{ width: '20%' }} >
                         <CustomDatePicker minDate={new Date(startDate)} placeholder={'Enter End Time'} onTextChange={(value) => {
                              fetchAllVehicles(selectedVehicleCategory.value, startDate, value, itemList);
                              setEndDate(value);
                         }} value={endDate} />
                    </div>
                    <div style={{ width: '10px' }} />
                    <div style={{ width: '20%' }} >
                         <CustomQuantitySelector currentValue={currentValue} setCurrentValue={(value) => {
                              if (value <= statusValue && value > 0) {
                                   return setCurrentValue(value);
                              }
                         }} placeholder={'Enter Quantity'} />
                    </div>
                    <div style={{ width: '10px' }} />
                    <div style={{ width: '20%', marginTop: '1.25%', }} >
                         <CustomButton style={{ width: '100%', backgroundColor: statusValue > 0 ? "#B061D0" : 'grey' }} onClick={async (e) => {
                              e.preventDefault();
                              if (!statusValue) {
                                   Timer(7000, 'Cannot add vehicle under this category!', 'danger');
                                   return;
                              }
                              if (Number(new Date()) > Number(new Date(startDate))) {
                                   Timer(7000, 'Start time cannot be before of current time.', 'danger');
                                   return;
                              }
                              if (new Date(startDate).toLocaleString() == new Date(endDate).toLocaleString()) {
                                   Timer(7000, 'Start time and end time cannot be same.', 'danger');
                                   return;
                              }
                              if (Number(new Date(startDate)) > Number(new Date(endDate))) {
                                   Timer(7000, 'End time cannot be before of start time.', 'danger');
                                   return;
                              }
                              if (!selectedVehicleCategory) {
                                   Timer(7000, 'Please select vehicle category first.', 'danger');
                                   return;
                              }
                              setItemList(
                                   (currentList) => {
                                        let cList = [...currentList, {
                                             id: currentList.length,
                                             start: `${startDate}`.split(' GMT+0530')[0],
                                             end: `${endDate}`.split(' GMT+0530')[0],
                                             vehicleCategoryValue: selectedVehicleCategory.value,
                                             vehicleCategoryName: selectedVehicleCategory.label,
                                             quantity: currentValue,
                                        }]
                                        fetchAllVehicles(selectedVehicleCategory.value, startDate, endDate, cList);
                                        return cList;
                                   }
                              );
                         }} text={'Add'} loading={false} />
                    </div>
               </div>
               {statusValue || statusValue == 0 ? <label style={{ marginBottom: '5px', color: statusValue > 0 ? 'green' : 'red', fontFamily: 'sans' }}>{statusValue > 0 ? `Max ${statusValue} vehicles available under this category!` : 'Sorry no vehicles available currently under this category!'}</label> : null}
               <div style={{ overflow: 'scroll', height: `${height}px` }}>
                    <div style={{ marginTop: 5, paddingLeft: 5, width: '100%', justifyContent: 'space-between', display: 'flex', backgroundColor: 'silver' }}><label style={{ width: '5%', fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>S. No</label><label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>Start</label><label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>End</label><label style={{ width: '15%', fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>Category</label><label style={{ width: '10%', fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>Quantity</label><label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>Action</label></div>
                    {itemList.map((element, index) => {
                         return <div style={{ paddingLeft: 5, width: '100%', justifyContent: 'space-between', display: 'flex', backgroundColor: index % 2 == 0 ? '#F4F5F7' : 'white' }}><label style={{ width: '5%', fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>{index + 1}</label><label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>{element.start}</label><label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>{element.end}</label><label style={{ width: '15%', fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>{element.vehicleCategoryName}</label><label style={{ width: '10%', fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>{element.quantity}</label>
                              <label onClick={() => {
                                   setItemList((vehicleList) => {
                                        let list = [];

                                        for (let i = 0; i < vehicleList.length; i++) {
                                             const element = vehicleList[i];
                                             if (index != i) {
                                                  list.push(element);
                                             }
                                        }
                                        fetchAllVehicles(selectedVehicleCategory.value, startDate, endDate, list);
                                        return list;
                                   });
                              }} style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'blue', textAlign: 'start', marginBottom: '5px' }}>Remove</label>
                         </div>;
                    })}
               </div>
               <CustomDivider />
               <div style={{
                    position: 'absolute',
                    bottom: 0, flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '55px', width: '95%', marginBottom: '10px'
               }} >

                    <div style={{ width: '10px' }} />
                    <div style={{ width: '20%', marginTop: '1.25%', }} >
                         <CustomButton style={{ width: '100%' }} onClick={async (e) => {
                              handleNewOrder(e, itemList, remarks);
                         }} text={'Review and Proceed!'} loading={false} />
                    </div>
               </div>
          </CustomCard>
          <CustomAlert info={alertInfo} />
     </div >
}

export default CreateOrderScreen;