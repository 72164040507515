import React, { useState } from "react";
import { FormControl, Form, FormLabel } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";

const CustomQuantitySelector = ({ placeholder, currentValue, setCurrentValue, inputType, onClick, text, loading, style }) => {

     

     return <Form.Group onClick={onClick} className="form-control" className="mb-3" controlId="formBasicPassword">
          <Form.Label style={{ fontSize: '14px', letterSpacing: 0.75, color: 'grey', textAlign: 'start', marginBottom: '5px' }}>{placeholder}</Form.Label>
          <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }} >
               <button style={{ ...style, height: '38px', border: "0", background: "#F3F3F3", borderRadius: "5px", width: "40%", boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.1)' }} type="submit" onClick={() => {
                    setCurrentValue(currentValue > 1 ? currentValue - 1 : currentValue);
               }} className="btn btn-primary btn-block">
                    <h3 style={{ color: "black", fontSize: "20px", fontWeight: 'bolder', letterSpacing: 0.5, }}>-</h3>
               </button>
               <div style={{ width: '10px' }} />
               <Form.Control style={{ fontSize: '14px', letterSpacing: 0.75, color: 'black', textAlign: 'center', height: '38px', }} onChange={function (element) {
                    setCurrentValue(element.target.value);
               }} type={'number'} value={currentValue} placeholder={''} />
               <div style={{ width: '10px' }} />
               <button style={{ ...style, height: '38px', border: "0", background: "#F3F3F3", borderRadius: "5px", width: "40%", boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.1)' }} type="submit" onClick={() => {

                    setCurrentValue(currentValue +1);
               }} className="btn btn-primary btn-block">
                    <h3 style={{ color: "black", fontSize: "20px", fontWeight: 'bolder', letterSpacing: 0.5, }}>+</h3>
               </button>
          </div>
     </Form.Group >
}

export default CustomQuantitySelector;