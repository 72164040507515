import React,{forwardRef} from "react";
import { FormControl, Form, FormLabel } from "react-bootstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomTextInput from "./CustomTextInput";




const CustomDatePicker = ({ placeholder, value, onTextChange, minDate }) => {
     const CustomInput = forwardRef(({ value, onClick }, ref) => (
          <CustomTextInput onClick={onClick} ref={ref} placeholder={placeholder} value={value} />
     ));
     return <Form.Group>
          <DatePicker customInput={<CustomInput/>} dateFormat="dd-MM-yyyy HH:mm" selected={value}
               minDate={minDate} onChange={onTextChange} showTimeSelect />
     </Form.Group>;
}

export default CustomDatePicker;