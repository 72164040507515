import React, { useEffect, useState, useRef } from "react";
import cookie from 'react-cookies';
import CustomLabel from '../components/CustomLabel';
import Calendar from '../screens/Calendar';
import CustomCard from '../components/CustomCard';

const WelcomeCalendarUserScreen = props => {

     const [orderList, setOrderList] = useState([]);
     const [selectedOrders, setSelectedOrders] = useState([]);
     const [height, setHeight] = useState(0);
     const [selectedDate, setSelectedDate] = useState('');
     let reload = false;

     function fetchAllDevices() {
          const requestOptions = {
               mode: 'cors',
               method: 'POST',
               headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Origin': 'http://smfal.speedotrack.com/' },
               body: new URLSearchParams({ userID: (cookie.load('UserID', { doNotParse: true }) ?? '') })
          };
          fetch(`http://smfal.speedotrack.com/api/user-orders`, requestOptions)
               .then(function (response) {
                    if (response.statusText === 'Unauthorized') {
                         return 'Unauthorized';
                    }
                    else {
                         return response.json();
                    }
               }).then(function (result) {
                    if ('success' in result) {
                         if (!result.success) {
                              return;
                         }
                    }
                    var list = [];
                    for (let index = 0; index < result.length; index++) {
                         for (let i = 0; i < result[index].vehicleList.length; i++) {
                              const element = {
                                   vehicleList: result[index].vehicleList[i],
                                   id: result[index]._id,
                                   sID: result[index].orderShownID
                              };
                              list.push(element);
                         }
                    }
                    setOrderList(list);
                    setSelectedDate(`${new Date()}`.split(':')[0].slice(0, 15));
                    let selectedOrders = [];
                    for (let index = 0; index < list.length; index++) {
                         const element = list[index];
                         if (new Date(element.vehicleList.start).getDate() == new Date().getDate()) {
                              if (element.vehicleList.isVehicle) {
                                   selectedOrders.push(element);
                              }
                         }
                    }
                    setSelectedOrders(selectedOrders);
               }).catch(function (params) {
                    setOrderList([]);
               });
     }

     useEffect(() => {
          fetchAllDevices();
          setHeight(myRef.current.clientWidth * 0.35);
     }, [reload])
     const myRef = useRef();

     return <div ref={myRef} style={{ width: '100%', height: '100%', backgroundColor: '#F4F5F7', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CustomCard style={{ width: '95%', }}>
               <CustomLabel text={'Vehicle Requisition Module'} />
               <div style={{ width: '100%', justifyContent: 'space-between', display: 'flex', }}>
                    <div style={{
                         width: '100%',
                         height: `${height}px`, justifyContent: 'space-between', display: 'flex',
                         overflow: 'scroll', flexDirection: 'column'
                    }}>
                         <Calendar allOrders={orderList} onDateSelect={(date) => {
                              let selectedOrders = [];
                              for (let index = 0; index < orderList.length; index++) {
                                   const element = orderList[index];
                                   if (new Date(element.vehicleList.start).getDate() == new Date(date).getDate()) {
                                        if (element.vehicleList.isVehicle) {
                                             selectedOrders.push(element);
                                        }
                                   }
                              }
                              setSelectedDate(`${date}`.split(':')[0].slice(0, 15));
                              setSelectedOrders(selectedOrders);
                         }} />
                         <label style={{ fontFamily: 'monospace', fontSize: '12px', color: 'black', textAlign: 'start', marginBottom: '10px' }}>*B: No of bookings for the day. D: Vehicles deployed for the selected date</label>
                    </div>
                    <div style={{ width: '10px' }} />
                    <div style={{ width: '60%', overflow: 'auto', height: `${height}px` }}>
                         <CustomLabel text={selectedDate} />
                         <div style={{ paddingLeft: 5, width: '100%', justifyContent: 'space-between', display: 'flex', backgroundColor: 'silver' }}>
                              <label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>Order ID</label>
                              <label style={{ flex: 2, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>Vehicles</label>
                         </div>
                         {selectedOrders.map((element, index) => {
                              return <div style={{ paddingLeft: 5, width: '100%', justifyContent: 'space-between', display: 'flex', backgroundColor: index % 2 == 0 ? '#F4F5F7' : 'white' }}>
                                   <label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>{element.sID}</label>
                                   <label style={{ flex: 2, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}><div><label>{element.vehicleList.vehicleCategoryName} - ({element.vehicleList.vehicleNumber})</label>
                                        <br />
                                        <div style={{ marginTop: 5, paddingLeft: 5, width: '100%', justifyContent: 'space-between', display: 'flex', backgroundColor: '#F6F6F6' }}>
                                             <label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>Start Time</label>
                                             <label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>End Time</label></div>
                                        <div style={{ paddingLeft: 5, width: '100%', justifyContent: 'space-between', display: 'flex', backgroundColor: 'white' }}>
                                             <label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>{element.vehicleList.start}</label>
                                             <label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>{element.vehicleList.end}</label></div>
                                   </div></label>
                              </div>
                         })}
                    </div>
               </div>
          </CustomCard>
     </div>;
}

export default WelcomeCalendarUserScreen;