import Spinner from "react-bootstrap/Spinner";
import React from "react";
import { FormControl, Form, FormLabel } from "react-bootstrap";

const CustomButton = ({ onClick, text, loading, style }) => {
     return <button style={{ ...style, height: '38px', border: "0", backgroundColor: style ? style.backgroundColor ? style.backgroundColor : "#B061D0" : "#B061D0", borderRadius: "5px", width: style ? style.width ? style.width : "80%" : "80%", boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.1)' }} type="submit" onClick={onClick} className="btn btn-primary btn-block">
          {loading ? <Spinner animation="border" role="status">
               <span className="visually-hidden">Loading...</span>
          </Spinner> : <h3 style={{ color: "white", marginTop: "5px", fontSize: "14px", letterSpacing: 0.5, }}>{text}</h3>}
     </button>;
}

export default CustomButton;