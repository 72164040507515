import React, { useState, useEffect } from 'react';
import CustomLabel from "../components/CustomLabel";
import CustomCountryPicker from "../components/CustomCountryPicker";
import CustomAlert from '../components/CustomAlert';
import CustomButton from '../components/CustomButton';
import CustomTextInput from '../components/CustomTextInput';
import { Form } from 'react-bootstrap';
import cookie from 'react-cookies';
import CustomCard from '../components/CustomCard';
import CustomDropDown from "../components/CustomDropDown";
import CustomDivider from '../components/CustomDivider';
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";


const VehicleStatusScreen = props => {
     const reload = false;
     const [userInfo, setUserInfo] = useState({ username: '', mobile: '', email: '', password: '', userLevel: 2, name: '' });
     const [loading, setLoading] = useState(false);
     const [department, setDepartmentList] = useState([]);
     const [selectedDepartment, setSelectedDepartment] = useState({ breakdown: false });
     const [usersList, setUsersList] = useState([]);
     const [alertInfo, setAlertInfo] = useState({ visibility: false, type: 'danger', message: '' });

     function Timer(time, message, type) {
          setAlertInfo({ visibility: true, type: type, message: message });
          setInterval(() => {
               setAlertInfo({ visibility: false, type: type, message: '' });
          }, time);
     }

     function handleCreateAccount(e) {
          e.preventDefault();
          setLoading(true);
          const requestOptions = {
               mode: 'cors',
               method: 'POST',
               headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Origin': 'http://smfal.speedotrack.com/' },
               body: new URLSearchParams({
                    vehicleID: selectedDepartment.value, status: selectedDepartment.breakdown
               })
          };
          fetch('http://smfal.speedotrack.com/api/update-vehicle', requestOptions)
               .then(function (response) {
                    if (response.statusText === 'Unauthorized') {
                         return 'Unauthorized';
                    }
                    else {
                         return response.json();
                    }
               }).then(function (result) {
                    if (result.success === true) {
                         setLoading(false);
                         Timer(7000, `Vehicle status updated successfully!`, result.success);
                    }
                    else {
                         setLoading(false);
                         Timer(7000, result.message, result.success);
                    }
               }).catch(function (params) {
                    setLoading(false);
                    Timer(7000, ' ' + params, false);
               });
     }

     function fetchAllDepartment() {
          const requestOptions = {
               mode: 'cors',
               method: 'POST',
               headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Origin': 'http://smfal.speedotrack.com/' },
               body: new URLSearchParams({ userID: (cookie.load('UserID', { doNotParse: true }) ?? '') })
          };
          fetch('http://smfal.speedotrack.com/api/all-vehicles', requestOptions)
               .then(function (response) {
                    if (response.statusText === 'Unauthorized') {
                         return 'Unauthorized';
                    }
                    else {
                         return response.json();
                    }
               }).then(function (result) {

                    const deviceModelList = [];
                    result.forEach(element => {
                         deviceModelList.push({ label: element.number, value: element._id, breakdown: element.breakdown });
                    });
                    setDepartmentList(deviceModelList);
                    setSelectedDepartment(deviceModelList[0]);
               }).catch(function (params) {
                    setDepartmentList([]);
                    Timer(7000, ' ' + params, false);
               });
     }


     useEffect(() => {
          fetchAllDepartment();
     }, [reload]);

     return <div style={{ width: '100%', height: '100%', backgroundColor: '#F4F5F7', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

          <CustomCard>
               <CustomLabel text={'Change Vehicle Status.'} />
               <CustomDivider />
               <Form style={{ color: 'black', textAlign: 'start', marginBottom: '10px' }}>
                    <CustomDropDown label={'Select Vehicle'} handleChange={(selectedOption) => {
                         console.log(selectedOption);
                         setSelectedDepartment(selectedOption);
                    }} list={department} />
                    <RadioGroup aria-label="anonymous" name="anonymous" value={selectedDepartment.breakdown ? 'Breakdown' : 'Working'} row style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }} onChange={(event) => { setSelectedDepartment((selectedDepartment) => { return { ...selectedDepartment, breakdown: event.target.value === 'Breakdown' } }); }}>
                         <FormControlLabel style={{ fontSize: '14px', letterSpacing: 0.75, color: 'grey', textAlign: 'start', marginBottom: '5px' }} value="Working" control={<Radio />} label="Working" />
                         <FormControlLabel style={{ fontSize: '14px', letterSpacing: 0.75, color: 'grey', textAlign: 'start', marginBottom: '5px' }} value="Breakdown" control={<Radio />} label="Breakdown" />
                    </RadioGroup>
                    <div style={{ flexDirection: 'row', display: 'flex', }}>
                         <CustomButton style={{ width: '100%' }} onClick={handleCreateAccount} text={'Update Vehicle Status'} loading={loading} />
                         <div style={{ width: '10px', height: '10px' }} />
                         <CustomButton style={{ width: '100%' }} onClick={()=>{
                              alert('Unable to connect to server.');
                         }} text={'Breakdown Report'} loading={loading} />
                    </div>
               </Form>
          </CustomCard>
          <CustomAlert info={alertInfo} />
     </div >
}

export default VehicleStatusScreen;