import React, { useState, useEffect, useRef } from 'react';
import CustomLabel from "../components/CustomLabel";
import CustomAlert from '../components/CustomAlert';
import CustomButton from '../components/CustomButton';
import { Form } from 'react-bootstrap';
import cookie from 'react-cookies';
import CustomCard from '../components/CustomCard';
import CustomDepartmentDropDown from "../components/CustomDepartmentDropDown";
import CustomDivider from '../components/CustomDivider';
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import CustomTextInput from '../components/CustomTextInput';
import CustomDatePicker from '../components/CustomDatePicker';
import CustomQuantitySelector from '../components/CustomQuantitySelector';


const ModifyOrderUserScreen = props => {
     const reload = false;
     const [userInfo, setUserInfo] = useState({ username: '', mobile: '', email: '', password: '', userLevel: 2, name: '' });
     const [loading, setLoading] = useState(false);
     const [remarks, setRemarks] = useState(props.editElement.remarks);
     const [height, setHeight] = useState(0);
     const [vehicleList, setVehicleList] = useState(props.editElement.vehicleList);

     const [alertInfo, setAlertInfo] = useState({ visibility: false, type: 'danger', message: '' });

     function Timer(time, message, type) {
          setAlertInfo({ visibility: true, type: type, message: message });
          setInterval(() => {
               setAlertInfo({ visibility: false, type: type, message: '' });
          }, time);
     }

     function handleOrder(e) {
          if (e) { // add?
               e.preventDefault();
               //      e.persist();
          }
          console.log(props.editElement._id);
          setLoading(true);
          const requestOptions = {
               mode: 'cors',
               method: 'POST',
               headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Origin': 'http://smfal.speedotrack.com/' },
               body: new URLSearchParams({ vehicles: JSON.stringify(vehicleList), remarks: remarks, orderID: props.editElement._id, orderStatus: '3', userID: (cookie.load('UserID', { doNotParse: true }) ?? '') })
          };
          fetch('http://smfal.speedotrack.com/api/update-order-modify', requestOptions)
               .then(function (response) {
                    return response.json();
               }).then(function (result) {
                    if (result.success === true) {
                         setLoading(false);
                         Timer(7000, `Order modified. Wait for review.`, result.success);
                    }
                    else {
                         setLoading(false);
                         Timer(7000, result.message, result.success);
                    }
               }).catch(function (params) {
                    setLoading(false);
                    Timer(7000, ' ' + params, false);
               });
     }

     useEffect(() => {
          setHeight(myRef.current.clientWidth * 0.29);
     }, [reload]);

     const myRef = useRef();
     let status = '';
     if (props.editElement.orderStatus == '1') {
          status = 'Pending';
     }
     else if (props.editElement.orderStatus == '2') {

          let s = true;
          for (let index = 0; index < vehicleList.length; index++) {
               const elementV = vehicleList[index];
               if (!elementV.isVehicle) {
                    s = false;
                    break;
               }
          }
          if (s) {
               status = 'Completed';
          } else {
               status = 'Partially Deployed';
          }
     }
     else if (props.editElement.orderStatus == '3') {
          status = 'Modification';
     }
     else if (props.editElement.orderStatus == '4') {
          status = 'Remarks';
     }
     return <div ref={myRef} style={{ width: '100%', height: '100%', backgroundColor: '#F4F5F7', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CustomCard style={{ width: '95%', height: '95%' }}>
               <div style={{ width: '100%', justifyContent: 'space-between', display: 'flex' }}>
                    <CustomLabel text={`Order ID - ${props.editElement.orderShownID}`} />
                    <CustomLabel text={`Order Date - ${props.editElement.order_date}`} />

               </div>
               <CustomDivider />
               <CustomLabel text={`Order Status: ${status}`} />
               <div style={{ marginTop: 5, paddingLeft: 5, width: '100%', justifyContent: 'space-between', display: 'flex', backgroundColor: 'silver' }}>
                    <label style={{ flex: 3, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>Vehicle Req.</label>
                    <label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px', paddingLeft: '10px' }}>Assigned</label>
                    <label style={{ flex: 2, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>Assigned Vehicle</label>
                    <label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>Actions</label>
               </div>
               <div style={{ overflow: 'auto', height: `${height}px` }}>
                    {vehicleList.map((element, index) => {
                         return <div style={{ paddingLeft: 5, width: '100%', justifyContent: 'space-between', display: 'flex', backgroundColor: index % 2 == 0 ? '#F4F5F7' : 'white' }}>
                              <label style={{ flex: 3, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>
                                   <div>
                                        <label>{element.vehicleCategoryName}
                                        </label>
                                        <label>.({element.quantity} Nos)
                                        </label>
                                        <br />
                                        <div style={{ marginTop: 5, paddingLeft: 5, width: '100%', justifyContent: 'space-between', display: 'flex', backgroundColor: '#F6F6F6' }}>
                                             <label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>Start Time</label>
                                             <label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>End Time</label>
                                        </div>
                                        <div style={{ paddingLeft: 5, width: '100%', justifyContent: 'space-between', display: 'flex', backgroundColor: 'white' }}>
                                             <label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>{element.start}</label>
                                             <label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>{element.end}</label>
                                        </div>
                                        <div style={{ paddingLeft: 5, width: '100%', justifyContent: 'space-between', display: 'flex', backgroundColor: 'white' }}>
                                             <label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}><CustomDatePicker minDate={new Date()} placeholder={'Enter New Start Time'} onTextChange={(value) => {
                                                  let list = [];
                                                  for (let i = 0; i < vehicleList.length; i++) {
                                                       const element = vehicleList[i];
                                                       if (i == index) {
                                                            list.push({
                                                                 ...element,
                                                                 start: `${value}`.split(' GMT+0530')[0],
                                                            });
                                                       }
                                                       else {
                                                            list.push(element);
                                                       }
                                                  }
                                                  setVehicleList(list);
                                             }} value={new Date(element.start)} /></label>
                                             <label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', paddingLeft: 5, textAlign: 'start', marginBottom: '5px' }}><CustomDatePicker minDate={new Date(element.start)} placeholder={'Enter New End Time'} onTextChange={(value) => {
                                                  let list = [];
                                                  for (let i = 0; i < vehicleList.length; i++) {
                                                       const element = vehicleList[i];
                                                       if (i == index) {
                                                            list.push({
                                                                 ...element,
                                                                 end: `${value}`.split(' GMT+0530')[0],
                                                            });
                                                       }
                                                       else {
                                                            list.push(element);
                                                       }
                                                  }
                                                  setVehicleList(list);
                                             }} value={new Date(element.end)} /></label>
                                        </div>
                                   </div>
                              </label>
                              <label style={{ flex: 1, paddingLeft: '10px', fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>{element.isVehicle ? 'Yes' : 'No'}</label>
                              {element.isVehicle ? <label style={{ flex: 2, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>{element.vehicleNumber}</label> :
                                   <div style={{ paddingLeft: 5, flexDirection: 'column', flex: 2, backgroundColor: 'white' }}><div style={{ width: '80%', }}><CustomQuantitySelector currentValue={Number(element.quantity)} setCurrentValue={(value) => {
                                        let list = [];

                                        for (let i = 0; i < vehicleList.length; i++) {
                                             const element = vehicleList[i];
                                             if (i == index) {
                                                  list.push({
                                                       ...element,
                                                       quantity: value,
                                                  });
                                             }
                                             else {
                                                  list.push(element);
                                             }
                                        }
                                        setVehicleList(list);
                                   }} placeholder={'Enter Quantity'} />
                                   </div>
                                        <CustomDepartmentDropDown label={`Change Vehicle Category or Delete (Current Selection:${element.vehicleCategoryName})`} handleChange={(selectedOption) => {
                                             let list = [];

                                             for (let i = 0; i < vehicleList.length; i++) {
                                                  const element = vehicleList[i];
                                                  if (i == index) {
                                                       list.push({
                                                            ...element,
                                                            vehicleCategoryName: selectedOption.label,
                                                       });
                                                  }
                                                  else {
                                                       list.push(element);
                                                  }
                                             }
                                             setVehicleList(list);
                                        }} vehicleData={element} /></div>}
                              {!element.isVehicle ? <label onClick={() => {
                                   let list = [];
                                   for (let i = 0; i < vehicleList.length; i++) {
                                        const element = vehicleList[i];
                                        if (i != index) {
                                             list.push(element);
                                        }
                                   }
                                   setVehicleList(list);
                              }} style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'blue', textAlign: 'start', marginBottom: '5px' }}>Delete</label> : <label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'blue', textAlign: 'start', marginBottom: '5px' }}></label>}
                         </div>;
                    })}
               </div>
               <div style={{
                    flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '55px', width: '100%',
               }} >
                    <div style={{ width: '80%' }} >
                         <CustomTextInput placeholder={'Enter Remarks'} value={remarks} onTextChange={setRemarks} inputType={'text'} />
                    </div>
                    <div style={{ width: '10px' }} />
                    <div style={{ width: '20%', marginTop: '1.20%', }} >
                         <CustomButton style={{ width: '100%' }} onClick={handleOrder} text={'Send for review'} loading={false} />
                    </div>
               </div>

          </CustomCard>
          <CustomAlert info={alertInfo} />
     </div >
}

export default ModifyOrderUserScreen;