import React from "react";
import { FormControl, Form, FormLabel } from "react-bootstrap";

const CustomTextInput = ({ placeholder, value, onTextChange, inputType,onClick }) => {
     return <Form.Group onClick={onClick} className="form-control" className="mb-3" controlId="formBasicPassword">
          <Form.Label style={{ fontSize: '14px', letterSpacing: 0.75, color: 'grey', textAlign: 'start', marginBottom: '5px' }}>{placeholder}</Form.Label>
          <Form.Control style={{ fontSize: '14px', letterSpacing: 0.75, color: 'black', textAlign: 'start',height:'38px', }} onChange={function (element) {
               onTextChange(element.target.value);
          }} type={inputType} value={value} placeholder={placeholder} />
     </Form.Group>;
}

export default CustomTextInput;