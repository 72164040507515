import React, { useEffect, useState } from "react";
import cookie from 'react-cookies';
import ModifyOrderScreen from "./ModifyOrderScreen";
import PendingOrderContentScreen from "./ViewAllPendingOrdersContentScreen";

const ViewAllPendingOrdersScreen = () => {
     const [alertInfo, setAlertInfo] = useState({ visibility: false, type: 'danger', message: '' });

     function Timer(time, message, type) {
          setAlertInfo({ visibility: true, type: type, message: message });
          setInterval(() => {
               setAlertInfo({ visibility: false, type: type, message: '' });
          }, time);
     }

     const [isEdit, setIsEdit] = useState(false);
     const [editElement, setEditElement] = useState(null);


     return (isEdit ? <ModifyOrderScreen editElement={editElement} onDeployed={setIsEdit}/> : <PendingOrderContentScreen onEdit={(element) => {
          setEditElement(element);
          setIsEdit(true);
     }} />);

}

export default ViewAllPendingOrdersScreen;