import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import CustomLabel from "../components/CustomLabel";
import CustomTextInput from "../components/CustomTextInput";
import CustomAlert from "../components/CustomAlert";
import CustomButton from "../components/CustomButton";
import CustomCard from "../components/CustomCard";
import CustomDivider from "../components/CustomDivider";
import cookie from 'react-cookies';

const CreateCategoryScreen = props => {
     const [alertInfo, setAlertInfo] = useState({ visibility: false, type: 'danger', message: 'Error: Email and password cannot be empty.' });
     const [modelInfo, setModelInfo] = useState('');
     const [vehicleCategory, setVehicleCategory] = useState([]);
     const [loading, setLoading] = useState(false);
     const reload = false;

     function Timer(time, message, type) {
          setAlertInfo({ visibility: true, type: type, message: message });
          setInterval(() => {
               setAlertInfo({ visibility: false, type: type, message: '' });
          }, time);
     }

     function handleRegisterDevice(e) {
          console.log(modelInfo);
          if (e) { // add?
               e.preventDefault();
          }
          if (!modelInfo) {
               Timer(5000, 'Kindly fill all the fields.', false);
          }
          else {
               setLoading(true);
               const requestOptions = {
                    mode: 'cors',
                    method: 'POST',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Origin': 'http://smfal.speedotrack.com/' },
                    body: new URLSearchParams({ category: modelInfo, userID: (cookie.load('UserID', { doNotParse: true }) ?? '') })
               };
               fetch('http://smfal.speedotrack.com/api/create-category', requestOptions)
                    .then(function (response) {
                         if (response.statusText === 'Unauthorized') {
                              return 'Unauthorized';
                         }
                         else {
                              return response.json();
                         }
                    }).then(function (result) {
                         console.log(result);
                         if (result.success === true) {
                              setLoading(false);
                              Timer(7000, `Category created successfully.`, result.success);
                              fetchAllDevicesModel();
                              setModelInfo('');
                         }
                         else {
                              setLoading(false);
                              Timer(7000, result.message, result.success);
                         }
                    }).catch(function (params) {
                         setLoading(false);
                         Timer(7000, ' ' + params, false);
                    });
          }
     }

     function fetchAllDevicesModel() {
          const requestOptions = {
               mode: 'cors',
               method: 'POST',
               headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Origin': 'http://smfal.speedotrack.com/' },
               body: new URLSearchParams({ userID: (cookie.load('UserID', { doNotParse: true }) ?? '') })
          };
          fetch('http://smfal.speedotrack.com/api/vehicle-category', requestOptions)
               .then(function (response) {
                    if (response.statusText === 'Unauthorized') {
                         return 'Unauthorized';
                    }
                    else {
                         return response.json();
                    }
               }).then(function (result) {

                    const deviceModelList = [];
                    result.forEach(element => {
                         deviceModelList.push(element.name);
                    });
                    setVehicleCategory(deviceModelList);
               }).catch(function (params) {
                    setVehicleCategory([]);
                    Timer(7000, ' ' + params, false);
               });
     }

     useEffect(() => {
          fetchAllDevicesModel();
     }, [reload]);

     return <div style={{ width: '100%', height: '100%', backgroundColor: '#F4F5F7', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CustomCard>
               <Form style={{ fontFamily: 'monospace', color: 'black', textAlign: 'start', marginBottom: '10px' }}>
                    <CustomLabel text={'Create Category'} />
                    <CustomDivider />
                    <CustomTextInput placeholder={'Enter Category'} value={modelInfo} onTextChange={setModelInfo} inputType={'text'} />
                    <CustomButton onClick={handleRegisterDevice} text={'Create Category'} loading={loading} />
               </Form>
          </CustomCard>
          <div style={{ width: '2.5%' }} />
          <CustomCard>
               <CustomLabel text={'All Categories'} />
               <CustomDivider />
               {vehicleCategory.map((element, index) => {
                    return <div><label>{index + 1}. {element}</label></div>
               })}
          </CustomCard>

          <CustomAlert info={alertInfo} />
     </div >
}

export default CreateCategoryScreen;