import React, { useEffect, useState } from "react";
import cookie from 'react-cookies';
import ModifyBreakdownCMTScreen from "./ModifyBreakdownCMTScreen";
import ModifyBreakdownUserScreen from "./ModifyBreakdownUserScreen";
import ModifyOrderUserScreen from "./ModifyOrderUserScreen";
import ReplacementOrderScreen from "./ReplacementOrderScreen";
import ViewAllBreakdownCMTContentScreen from "./ViewAllBreakdownCMTContentScreen";
import ViewAllBreakdownUserContentScreen from "./ViewAllBreakdownUserContentScreen";
import ViewAllOrdersContentScreen from "./ViewAllOrdersContentScreen";

const ViewAllBreakdownCMTScreen = () => {
     const [alertInfo, setAlertInfo] = useState({ visibility: false, type: 'danger', message: '' });

     function Timer(time, message, type) {
          setAlertInfo({ visibility: true, type: type, message: message });
          setInterval(() => {
               setAlertInfo({ visibility: false, type: type, message: '' });
          }, time);
     }

     const [view, setView] = useState(<ViewAllBreakdownCMTContentScreen onReplacement={(element) => {
          setView(<ReplacementOrderScreen editElement={element} />);
     }} onEdit={(element) => {
          setView(<ModifyBreakdownCMTScreen onComplete={onComplete} editElement={element} />);
     }} />);

     const onComplete = () => {
          setView(<ViewAllBreakdownCMTContentScreen onReplacement={(element) => {
               setView(<ReplacementOrderScreen editElement={element} />);
          }} onEdit={(element) => {
               setView(<ModifyBreakdownCMTScreen onComplete={onComplete} editElement={element} />);
          }} />);
     }

     return (view);

}

export default ViewAllBreakdownCMTScreen;