import React, { useEffect, useState, useRef } from "react";
import cookie from 'react-cookies';
import Spinner from "react-bootstrap/Spinner";
import { FaEdit, FaSearchLocation, FaTrashAlt } from 'react-icons/fa';
import { Form } from "react-bootstrap";
import CustomAlert from "../components/CustomAlert";
import CustomLabel from "../components/CustomLabel";
import CustomCard from "../components/CustomCard";
import CustomButton from "../components/CustomButton";
import CustomDivider from "../components/CustomDivider";

const ViewAllOrdersScreen = props => {
     const [alertInfo, setAlertInfo] = useState({ visibility: false, type: 'danger', message: '' });
     const [height, setHeight] = useState(0);

     function Timer(time, message, type) {
          setAlertInfo({ visibility: true, type: type, message: message });
          setInterval(() => {
               setAlertInfo({ visibility: false, type: type, message: '' });
          }, time);
     }

     const [orderList, setOrderList] = useState([]);
     const [selectedOrders, setSelectedOrders] = useState([]);
     const [selectedDate, setSelectedDate] = useState('');

     let reload = false;

     function fetchAllDevices() {
          const requestOptions = {
               mode: 'cors',
               method: 'POST',
               headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Origin': 'http://smfal.speedotrack.com/' },
               body: new URLSearchParams({ userID: (cookie.load('UserID', { doNotParse: true }) ?? '') })
          };
          fetch('http://smfal.speedotrack.com/api/all-orders', requestOptions)
               .then(function (response) {
                    if (response.statusText === 'Unauthorized') {
                         return 'Unauthorized';
                    }
                    else {
                         return response.json();
                    }
               }).then(function (result) {
                    if ('success' in result) {
                         if (!result.success) {
                              Timer(7000, result.message, 'danger');
                              return;
                         }
                    }
                    const requestOptions = {
                         mode: 'cors',
                         method: 'POST',
                         headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Origin': 'http://smfal.speedotrack.com/' },
                         body: new URLSearchParams({ userID: (cookie.load('UserID', { doNotParse: true }) ?? '') })
                    };
                    fetch('http://smfal.speedotrack.com/api/vehicle-category', requestOptions)
                         .then(function (response) {
                              if (response.statusText === 'Unauthorized') {
                                   return 'Unauthorized';
                              }
                              else {
                                   return response.json();
                              }
                         }).then(function (resultC) {
                              const deviceModelList = [];
                              resultC.forEach(element => {
                                   deviceModelList.push({ label: element.name, value: element._id });
                              });
                              var list = [];
                              for (let index = 0; index < result.length; index++) {
                                   if (result[index].orderStatus == '1' || result[index].orderStatus == '2') {
                                             let s = true;
                                             for (let k = 0; k < result[index].vehicleList.length; k++) {
                                                  const elementV = result[index].vehicleList[k];
                                                  if (!elementV.isVehicle) {
                                                       s = false;
                                                       break;
                                                  }
                                                  else {
                                                       s = true;
                                                  }
                                             }
                                             if (!s) {

                                                  for (let i = 0; i < result[index].vehicleList.length; i++) {

                                                       let value = '';
                                                       deviceModelList.forEach(element => {
                                                            if (element.label == result[index].vehicleList[i].vehicleCategoryName) {
                                                                 value = element.value;
                                                            }
                                                       });
                                                       if (!result[index].vehicleList[i].isVehicle) {
                                                            const element = {
                                                                 vehicleList: { ...result[index].vehicleList[i], vehicleCategoryValue: value },
                                                                 id: result[index]._id,
                                                                 sID: result[index].orderShownID,
                                                                 date: result[index].order_date,
                                                                 user_id: result[index].user_id,
                                                                 remarks: result[index].remarks
                                                            };

                                                            list.push(element);
                                                       }
                                                  }
                                             }
                                   }

                              }
                              setOrderList(list);
                              setSelectedDate(`${new Date()}`.split(':')[0].slice(0, 15));
                              let selectedOrders = [];
                              for (let index = 0; index < list.length; index++) {
                                   const element = list[index];
                                   if (new Date(element.vehicleList.start).getDate() == new Date().getDate()) {
                                        selectedOrders.push(element);
                                   }
                              }

                              setSelectedOrders(selectedOrders.reverse());
                         }).catch(function (params) {
                              Timer(7000, ' ' + params, false);
                         });
               }).catch(function (params) {
                    setOrderList([]);
                    Timer(7000, ' ' + params, false);
               });
     }


     const looper = () => {
          setInterval(() => {
               console.log('Here');
               fetchAllDevices();
          }, 30000);
     }


     useEffect(() => {
          fetchAllDevices();
          looper();
          setHeight(myRef.current.clientWidth * 0.3);
     }, [reload])

     const myRef = useRef();

     return <div ref={myRef} style={{ width: '100%', height: '100%', backgroundColor: '#F4F5F7', padding: '1%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
          <CustomCard style={{ width: '95%', height: '95%' }}>
               <CustomLabel text={`Deployment for date ${selectedDate}`} />
               <CustomDivider />
               <div style={{ marginTop: 5, paddingLeft: 5, width: '100%', justifyContent: 'space-between', display: 'flex', backgroundColor: 'silver' }}>
                    <label style={{ width: '10%', fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>Order ID</label>
                    <label style={{ flex: 2, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>Vehicle Req.</label>
                    <label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>Order Date</label>
                    <label style={{ width: '15%', fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>Action</label>
               </div>
               <div style={{ overflow: 'scroll', height: `${height}px` }}>
                    {selectedOrders.map((element, index) => {
                         return <div style={{ paddingLeft: 5, width: '100%', justifyContent: 'space-between', display: 'flex', backgroundColor: index % 2 == 0 ? '#F4F5F7' : 'white' }}>
                              <label style={{ width: '10%', fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>{element.sID}</label>
                              <label style={{ flex: 2, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}><div><label>{element.vehicleList.vehicleCategoryName} - ({element.vehicleList.quantity} Nos)</label>
                                   <br />
                                   <div style={{ marginTop: 5, paddingLeft: 5, width: '100%', justifyContent: 'space-between', display: 'flex', backgroundColor: '#F6F6F6' }}>
                                        <label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>Start Time</label>
                                        <label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>End Time</label></div>
                                   <div style={{ paddingLeft: 5, width: '100%', justifyContent: 'space-between', display: 'flex', backgroundColor: 'white' }}>
                                        <label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>{element.vehicleList.start}</label>
                                        <label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>{element.vehicleList.end}</label></div>
                              </div>
                              </label>
                              <label style={{
                                   flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start',
                                   marginBottom: '5px'
                              }}>{element.date}</label>
                              <label onClick={() => {
                                   props.onEdit(element);
                              }} style={{ width: '15%', fontFamily: 'monospace', fontSize: '14px', color: 'blue', textAlign: 'start', marginBottom: '5px' }}>Deploy</label>
                         </div>
                    })}
               </div>
          </CustomCard>
          {/* {orderList ? <div style={{ overflow: "scroll", width: '100%', height: '100%', padding: '1%' }} >
               <div style={{ fontSize: '24px', width: '100%', marginBottom: '5px', display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                    <Form.Label style={{ fontSize: '24px', letterSpacing: 0.75, textAlign: 'start', width: '80%', height: 'auto' }}>Orders</Form.Label>
               </div>


               <div style={{
                    backgroundColor: 'white',
                    padding: '5px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    borderRadius: '5px',
                    boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.2)',
                    marginBottom: '5px'
               }}>
                    <Form.Label style={{ fontSize: '16px', letterSpacing: 0.75, textAlign: 'start', fontWeight: 'bold', width: '18%', marginBottom: '5px', fontFamily: 'serif' }}>Order ID</Form.Label>
                    <Form.Label style={{ fontSize: '16px', fontWeight: 'bold', letterSpacing: 0.75, textAlign: 'start', width: '10%', marginBottom: '5px', fontFamily: 'serif' }}>Order Status</Form.Label>
                    <Form.Label style={{ fontSize: '16px', letterSpacing: 0.75, fontWeight: 'bold', textAlign: 'start', width: '10%', marginBottom: '5px', fontFamily: 'serif' }}>Total Vehicles</Form.Label>
                    <Form.Label style={{ fontSize: '16px', letterSpacing: 0.75, textAlign: 'start', fontWeight: 'bold', width: '35%', marginBottom: '5px', fontFamily: 'serif' }}>Vehicles List</Form.Label>
                    <Form.Label style={{ fontSize: '16px', fontWeight: 'bold', letterSpacing: 0.75, textAlign: 'start', width: '15%', marginBottom: '5px', fontFamily: 'serif' }}>Order Date</Form.Label>
                    <Form.Label style={{ fontSize: '16px', fontWeight: 'bold', letterSpacing: 0.75, textAlign: 'start', width: '12%', marginBottom: '5px', fontFamily: 'serif' }}>Action</Form.Label>
               </div>
               {
                    orderList.map((element) => {
                         let status = '';
                         if (element.orderStatus == '1') {
                              status = 'Pending';
                         }
                         else if (element.orderStatus == '2') {
                              status = 'Approved';
                         }
                         else if (element.orderStatus == '3') {
                              status = 'Modification';
                         }
                         return <div style={{
                              backgroundColor: 'white',
                              padding: '5px',
                              paddingLeft: '10px',
                              paddingRight: '10px',
                              alignItems: 'start',
                              justifyContent: 'start',
                              display: 'flex',
                              boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.2)',
                         }}>
                              <Form.Label style={{ fontSize: '14px', letterSpacing: 0.75, textAlign: 'start', width: '18%', marginBottom: '5px' }}>{element._id}</Form.Label>
                              <Form.Label style={{ fontSize: '14px', letterSpacing: 0.75, textAlign: 'start', width: '10%', marginBottom: '5px' }}>{status}</Form.Label>
                              <Form.Label style={{ fontSize: '14px', letterSpacing: 0.75, textAlign: 'start', width: '10%', marginBottom: '5px' }}>{element.vehicleList.length}</Form.Label>
                              <Form.Label style={{ width: '35%', marginBottom: '5px' }}>
                                   {element.vehicleList.map((item) => {
                                        let space = '';
                                        for (let index = 0; index < item.vehicleCategoryName.length; index++) {
                                             space = space + '-';
                                        }
                                        return item.isVehicle ? <div><Form.Label style={{ fontSize: '14px', letterSpacing: 0.75, textAlign: 'start', fontWeight: 'bold' }}>{item.vehicleCategoryName} - {item.vehicleNumber}</Form.Label>
                                             <br />
                                             <Form.Label style={{ fontSize: '14px', letterSpacing: 0.75, textAlign: 'start', }}>Start Time: {item.start}</Form.Label>
                                             <br />
                                             <Form.Label style={{ fontSize: '14px', letterSpacing: 0.75, textAlign: 'start', }}>End Time: {item.end}</Form.Label>
                                             <br />
                                             <Form.Label style={{ fontSize: '14px', letterSpacing: 0.75, textAlign: 'start', }}>------------------</Form.Label>
                                        </div> : <div><Form.Label style={{ fontSize: '14px', letterSpacing: 0.75, textAlign: 'start', fontWeight: 'bold' }}>{item.vehicleCategoryName}</Form.Label>
                                             <br />
                                             <Form.Label style={{ fontSize: '14px', letterSpacing: 0.75, textAlign: 'start', }}>Start Time: {item.start}</Form.Label>
                                             <br />
                                             <Form.Label style={{ fontSize: '14px', letterSpacing: 0.75, textAlign: 'start', }}>End Time: {item.end}</Form.Label>
                                             <br />
                                             <Form.Label style={{ fontSize: '14px', letterSpacing: 0.75, textAlign: 'start', }}>------------------</Form.Label>
                                        </div>
                                   })}
                              </Form.Label>
                              <Form.Label style={{ fontSize: '14px', letterSpacing: 0.75, textAlign: 'start', width: '15%', marginBottom: '5px' }}>{element.order_date}</Form.Label>
                              <div style={{ display: 'inline', width: '12%' }}>
                                   <FaEdit size={18} class="center" style={{ marginLeft: 5, marginRight: 5, color: 'blue' }} onClick={() => {
                                        props.onEdit(element);
                                   }} />

                              </div>
                         </div>
                    })
               }
          </div > :
               <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
               </Spinner>} */}
          <CustomAlert info={alertInfo} />
     </div>;

}

export default ViewAllOrdersScreen;