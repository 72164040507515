import React, { useEffect, useState, useRef } from "react";
import cookie from 'react-cookies';
import Spinner from "react-bootstrap/Spinner";
import { FaEdit } from 'react-icons/fa';
import { MdFindReplace } from 'react-icons/md';
import { Form } from "react-bootstrap";
import CustomAlert from "../components/CustomAlert";
import CustomCard from "../components/CustomCard";
import CustomLabel from "../components/CustomLabel";
import CustomDivider from "../components/CustomDivider";

const ViewAllOrdersContentScreen = props => {
     const [alertInfo, setAlertInfo] = useState({ visibility: false, type: 'danger', message: '' });
     const [height, setHeight] = useState(0);

     function Timer(time, message, type) {
          setAlertInfo({ visibility: true, type: type, message: message });
          setInterval(() => {
               setAlertInfo({ visibility: false, type: type, message: '' });
          }, time);
     }

     const [orderList, setOrderList] = useState(null);

     let reload = false;

     function fetchAllDevices() {
          const requestOptions = {
               mode: 'cors',
               method: 'POST',
               headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Origin': 'http://smfal.speedotrack.com/' },
               body: new URLSearchParams({ userID: (cookie.load('UserID', { doNotParse: true }) ?? '') })
          };
          fetch('http://smfal.speedotrack.com/api/user-orders', requestOptions)
               .then(function (response) {
                    if (response.statusText === 'Unauthorized') {
                         return 'Unauthorized';
                    }
                    else {
                         return response.json();
                    }
               }).then(function (result) {
                    if ('success' in result) {
                         if (!result.success) {
                              Timer(7000, result.message, 'danger');
                              return;
                         }
                    }
                    setOrderList(result.reverse());
               }).catch(function (params) {
                    setOrderList([]);
                    Timer(7000, ' ' + params, false);
               });
     }

     const looper = () => {
          setInterval(() => {
               console.log('Here');
               fetchAllDevices();
          }, 30000);
     }


     useEffect(() => {
          fetchAllDevices();
          looper();
          setHeight(myRef.current.clientWidth * 0.3);
     }, [reload])

     const myRef = useRef();

     return <div ref={myRef} style={{ width: '100%', height: '100%', backgroundColor: '#F4F5F7', padding: '1%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
          {orderList ? <CustomCard style={{ width: '90%', height: '100%', position: 'relative', }}>
               <CustomLabel text={'Hello Orders'} />
               <CustomDivider />
               <div style={{ marginTop: 5, paddingLeft: 5, width: '100%', justifyContent: 'space-between', display: 'flex', backgroundColor: 'silver' }}>
                    <label style={{ width: '10%', fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>Order ID</label>
                    <label style={{ width: '10%', fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>Status</label>
                    <label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>Vehicles</label>
                    <label style={{ width: '20%', fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>Date</label>
                    <label style={{ width: '10%', fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>Action</label>
               </div>
               <div style={{ overflow: 'scroll', height: `${height}px` }}>
                    {
                         orderList.map((element, index) => {
                              let status = '';
                              if (element.orderStatus == '1') {
                                   status = 'Pending';
                              }
                              else if (element.orderStatus == '2') {
                                   let s = true;
                                   for (let index = 0; index < element.vehicleList.length; index++) {
                                        const elementV = element.vehicleList[index];
                                        if (!elementV.isVehicle) {
                                             s = false;
                                             break;
                                        }
                                   }
                                   if (s) {
                                        status = 'Completed';
                                   } else {
                                        status = 'P. Deployed';
                                   }
                              }
                              else if (element.orderStatus == '3') {
                                   status = 'Modification';
                              }
                              else if (element.orderStatus == '4') {
                                   status = 'Remarks';
                              }
                              else if (element.orderStatus == '5') {
                                   status = 'Replacement';
                              }
                              return <div style={{ paddingLeft: 5, width: '100%', justifyContent: 'space-between', display: 'flex', backgroundColor: index % 2 == 0 ? '#F4F5F7' : 'white' }}>
                                   <label style={{ width: '10%', fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>{element.orderShownID}</label>
                                   <label style={{ width: '10%', fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>{status}</label><label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>{element.vehicleList.map((item) => {
                                        return <div><label>{item.vehicleCategoryName}{item.quantity ? ` - (${item.quantity} Nos)` : ` - (V. No: ${item.vehicleNumber})`}</label>
                                             <br />
                                             <div style={{ marginTop: 5, paddingLeft: 5, width: '100%', justifyContent: 'space-between', display: 'flex', backgroundColor: '#F6F6F6' }}>
                                                  <label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>Start Time</label>
                                                  <label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>End Time</label></div>
                                             <div style={{ paddingLeft: 5, width: '100%', justifyContent: 'space-between', display: 'flex', backgroundColor: 'white' }}>
                                                  <label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>{item.start}</label>
                                                  <label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>{item.end}</label></div>
                                        </div>
                                   })}</label><label style={{ width: '20%', fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>{element.order_date}</label><label onClick={() => {
                                        props.onEdit(element);
                                   }} style={{ width: '10%', fontFamily: 'monospace', fontSize: '14px', color: 'blue', textAlign: 'start', marginBottom: '5px' }}>Details</label></div>
                         })}
               </div>
          </CustomCard> : <Spinner animation="border" role="status">
               <span className="visually-hidden">Loading...</span>
          </Spinner>}


          {/* {orderList ? <div style={{ overflow: "scroll", width: '100%', height: '100%', padding: '1%' }} >


               <div style={{
                    backgroundColor: 'white',
                    padding: '5px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    borderRadius: '5px',
                    boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.2)',
                    marginBottom: '5px'
               }}>
                    <Form.Label style={{ fontSize: '16px', letterSpacing: 0.75, fontWeight: 'bold', textAlign: 'start', width: '10%', marginBottom: '5px', fontFamily: 'serif' }}>Total Vehicles</Form.Label>
                    <Form.Label style={{ fontSize: '16px', letterSpacing: 0.75, textAlign: 'start', fontWeight: 'bold', width: '35%', marginBottom: '5px', fontFamily: 'serif' }}>Vehicles List</Form.Label>
                    <Form.Label style={{ fontSize: '16px', fontWeight: 'bold', letterSpacing: 0.75, textAlign: 'start', width: '15%', marginBottom: '5px', fontFamily: 'serif' }}>Order Date</Form.Label>
                    <Form.Label style={{ fontSize: '16px', fontWeight: 'bold', letterSpacing: 0.75, textAlign: 'start', width: '12%', marginBottom: '5px', fontFamily: 'serif' }}>Action</Form.Label>
               </div>
               {
                    orderList.map((element) => {
                         let status = '';
                         if (element.orderStatus == '1') {
                              status = 'Pending';
                         }
                         else if (element.orderStatus == '2') {
                              status = 'Approved';
                         }
                         else if (element.orderStatus == '3') {
                              status = 'Modification';
                         }
                         return <div style={{
                              backgroundColor: 'white',
                              padding: '5px',
                              paddingLeft: '10px',
                              paddingRight: '10px',
                              alignItems: 'start',
                              justifyContent: 'start',
                              display: 'flex',
                              boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.2)',
                         }}>
                              <Form.Label style={{ fontSize: '14px', letterSpacing: 0.75, textAlign: 'start', width: '18%', marginBottom: '5px' }}>{element._id}</Form.Label>
                              <Form.Label style={{ fontSize: '14px', letterSpacing: 0.75, textAlign: 'start', width: '10%', marginBottom: '5px' }}>{status}</Form.Label>
                              <Form.Label style={{ fontSize: '14px', letterSpacing: 0.75, textAlign: 'start', width: '10%', marginBottom: '5px' }}>{element.vehicleList.length}</Form.Label>
                              <Form.Label style={{ width: '35%', marginBottom: '5px' }}>
                                   {element.vehicleList.map((item) => {
                                        return <div><Form.Label style={{ fontSize: '14px', letterSpacing: 0.75, textAlign: 'start', fontWeight: 'bold' }}>{item.vehicleCategoryName} - {item.vehicleNumber}</Form.Label>
                                             <br />
                                             <Form.Label style={{ fontSize: '14px', letterSpacing: 0.75, textAlign: 'start', }}>Start Time: {item.start}</Form.Label>
                                             <br />
                                             <Form.Label style={{ fontSize: '14px', letterSpacing: 0.75, textAlign: 'start', }}>End Time: {item.end}</Form.Label>
                                             <br />
                                             <Form.Label style={{ fontSize: '14px', letterSpacing: 0.75, textAlign: 'start', }}>------------------</Form.Label>
                                        </div>
                                   })}
                              </Form.Label>
                              <Form.Label style={{ fontSize: '14px', letterSpacing: 0.75, textAlign: 'start', width: '15%', marginBottom: '5px' }}>{element.order_date}</Form.Label>
                              <div style={{ display: 'inline', width: '12%' }}>

                                   <MdFindReplace size={18} class="center" style={{ marginLeft: 5, marginRight: 5, color: 'blue' }} onClick={() => {
                                        props.onReplacement(element);
                                   }} />
                                   {status == 'Approved' ? null : <FaEdit size={18} class="center" style={{ marginLeft: 5, marginRight: 5, color: 'blue' }} onClick={() => {
                                        props.onEdit(element);
                                   }} />}

                              </div>
                         </div>
                    })
               }
          </div > :
                */}
          <CustomAlert info={alertInfo} />
     </div>;

}

export default ViewAllOrdersContentScreen;