import React, { useEffect, useState, useRef } from "react";
import cookie from 'react-cookies';
import CreateAccountScreen from "./screens/CreateAccountScreen";
import { FaCartPlus, FaSync, FaRegCalendarAlt, FaRegListAlt, FaHistory, FaUserPlus } from 'react-icons/fa';
import { GiAutoRepair, GiNetworkBars } from 'react-icons/gi';
import Sidebar from "./components/CustomSidebar";
import CreateDepartmentScreen from "./screens/CreateDepartmentScreen";
import CreateOrderScreen from "./screens/CreateOrderScreen";
import CreateVehicleScreen from "./screens/CreateVehicleScreen";
import CreateCategoryScreen from "./screens/CreateCategoryScreen";
import ViewAllOrdersScreen from "./screens/ViewAllOrdersScreen";
import ViewAllOrdersCMTScreen from "./screens/ViewAllOrdersCMTScreen";
import ViewAllPendingOrdersScreen from "./screens/ViewAllPendingOrdersScreen";
import WelcomeCalendarCMTScreen from "./screens/WelcomeCalendarCMTScreen";
import WelcomeCalendarUserScreen from "./screens/WelcomeCalendarUserScreen";
import ViewAllBreakdownUserScreen from "./screens/ViewAllBreakdownUserScreen";
import ViewAllBreakdownCMTScreen from "./screens/ViewAllBreakdownCMTScreen";
import VehicleStatusScreen from "./screens/VehicleStatusScreen";

const SiteAdminHome = props => {
     const screens = (cookie.load('AdminLevel', { doNotParse: true }) ?? '') == '1' ? [
          { icon: <FaRegCalendarAlt className="sidebar-icon" />, name: 'Calendar', key: 'calendar' },
          { icon: <FaHistory className="sidebar-icon" />, name: 'Pending Orders', key: 'pending_orders' },
          { icon: <GiAutoRepair className="sidebar-icon" />, name: 'Deployed', key: 'breakdown_cmt' },
          { icon: <FaRegListAlt className="sidebar-icon" />, name: 'All Orders', key: 'all_orders' },
          { icon: <FaSync className="sidebar-icon" />, name: 'Sync', key: 'vehicle_list' },
          { icon: <FaUserPlus className="sidebar-icon" />, name: 'Create User', key: 'create_user' },
          { icon: <GiNetworkBars className="sidebar-icon" />, name: 'Breakdown', key: 'vehicle_status' },
     ] : [
          { icon: <FaRegCalendarAlt className="sidebar-icon" />, name: 'Calendar', key: 'calendar' },
          { icon: <FaCartPlus className="sidebar-icon" />, name: 'New Order', key: 'new_order' },
          { icon: <FaHistory className="sidebar-icon" />, name: 'Orders', key: 'orders' },
          { icon: <GiAutoRepair className="sidebar-icon" />, name: 'Deployed', key: 'breakdown_user' },
     ];
     const [selectedScreen, setselectedScreen] = useState((cookie.load('AdminLevel', { doNotParse: true }) ?? '') == '1' ? <WelcomeCalendarCMTScreen /> : <WelcomeCalendarUserScreen />);
     const [isExpanded, setIsExpanded] = useState(false);
     const [selectedScreenKey, setselectedScreenKey] = useState('calendar');
     const [width, setWidth] = useState(96);

     if ((cookie.load('Status', { doNotParse: true }) ?? false) === false) {
          props.history.replace('site-admin');
          return;
     }

     const handleToggler = () => {
          if (isExpanded) {
               requestAnimationFrame(() => {
                    setWidth(96);
               });

               setIsExpanded(false);
               return;
          }
          else {
               requestAnimationFrame(() => {
                    setWidth(85);
               });
               setIsExpanded(true);
          }
     }

     function handleSidebarClick(key) {
          setselectedScreenKey(key);
          switch (key) {
               case 'calendar': setselectedScreen((cookie.load('AdminLevel', { doNotParse: true }) ?? '') == '1' ? <WelcomeCalendarCMTScreen /> : <WelcomeCalendarUserScreen />);
                    break;
               case 'department': setselectedScreen(<CreateDepartmentScreen />);
                    break;
               case 'vehicle_category': setselectedScreen(<CreateCategoryScreen />);
                    break;
               case 'vehicle_list': setselectedScreen(<CreateVehicleScreen />);
                    break;
               case 'create_user': setselectedScreen(<CreateAccountScreen />);
                    break;
               case 'new_order': setselectedScreen(<CreateOrderScreen onOrderSuccess={setselectedScreen} />);
                    break;
               case 'pending_orders': setselectedScreen(<ViewAllPendingOrdersScreen />);
                    break;
               case 'breakdown_cmt': setselectedScreen(<ViewAllBreakdownCMTScreen />);
                    break;
               case 'breakdown_user': setselectedScreen(<ViewAllBreakdownUserScreen />);
                    break;
               case 'all_orders': setselectedScreen(<ViewAllOrdersCMTScreen />);
                    break;
               case 'vehicle_status': setselectedScreen(<VehicleStatusScreen/>);
                    break; 
               case 'orders': setselectedScreen(<ViewAllOrdersScreen />);
                    break;
          }
     }

     return <div style={{ backgroundColor: '#F0E5CF', height: '100%', width: '100%', }}>

          <div style={{ backgroundColor: '#F4F5F7', height: '92.5%', width: '100%', right: 0, position: 'absolute', top: '7.5%', display: 'flex', paddingLeft: '5%' }}>
               {selectedScreen}
          </div>

          <div style={{ backgroundColor: '#FFFFFF', height: '7.5%', width: '100%', position: 'absolute', left: 0, alignItems: 'center', display: 'flex', boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.1)', borderBottomRightRadius: '5px' }}>
               <img style={{ height: "60%", width: "auto", marginLeft: 10 }} src={process.env.PUBLIC_URL + '/images/colored_logo.png'} alt="dashboard_image" />
          </div>

          <Sidebar props={props} isExpanded={isExpanded} handleToggler={handleToggler} menu={screens} onItemClick={handleSidebarClick} selectedKey={selectedScreenKey} widthState={width} />

     </div>;
}

export default SiteAdminHome;


//Name,email,mobile,active,login,monitor,edit,delete,