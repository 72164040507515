import React from "react";
import { Form } from "react-bootstrap";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const CustomCountryPicker = ({ children, placeholder, value, onTextChange, inputType }) => {
     return <Form.Group className="form-control" className="mb-3" controlId="formBasicPassword">
          <Form.Label style={{ fontSize: '14px', letterSpacing: 0.75, color: 'grey', textAlign: 'start', marginBottom: '10px' }}>{children}</Form.Label>
          <PhoneInput style={{ fontSize: '14px', width: '100%', letterSpacing: 0.75, color: 'grey', textAlign: 'start', marginBottom: '10px' }}
               country={'in'}
               onChange={onTextChange} value={value} placeholder={placeholder}
          />
     </Form.Group>;
}

export default CustomCountryPicker;