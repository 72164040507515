import React from "react";
import { Card, Form } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import cookie from 'react-cookies'
import Spinner from "react-bootstrap/Spinner";
import CustomButton from './components/CustomButton';

function SiteAdminLogin(props) {

     const [visibility, setVisibility] = React.useState(false);
     const [loading, setLoading] = React.useState(false);
     const [alertMessage, setAlertMessage] = React.useState('Error: Email and password cannot be empty.');
     const [email, setEmail] = React.useState('');
     const [password, setPassword] = React.useState('');


     if (cookie.load('Status', { doNotParse: true }) ?? false) {
          props.history.replace('home');
     }

     function saveCookies(value) {
          cookie.save('Status', JSON.parse(value).status, {
               path: '/',
               secure: false
          })
          cookie.save('UserID', JSON.parse(value).userID, {
               path: '/',
               secure: false
          })
          cookie.save('Email', JSON.parse(value).email, {
               path: '/',
               secure: false
          })
          cookie.save('Admin', JSON.parse(value).admin, {
               path: '/',
               secure: false
          })
          cookie.save('AdminLevel', JSON.parse(value).adminLevel, {
               path: '/',
               secure: false
          })
          props.history.replace('home');
     }

     function Timer(time, message) {
          setAlertMessage(message);
          setVisibility(true);
          setInterval(() => {
               setVisibility(false);
          }, time);
     }

     function handleLogin(e) {
          if (e) { // add?
               e.preventDefault();
          }
          if (!email || !password) {
               Timer(5000, 'Email and Password cannot be empty.');
          }
          else {
               setLoading(true);
               const requestOptions = {
                    mode: 'cors',
                    method: 'POST',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Origin': 'http://smfal.speedotrack.com/' },
                    body: new URLSearchParams({ username: email, password: password })
               };
               fetch('http://smfal.speedotrack.com/api/login', requestOptions)
                    .then(function (response) {
                         if (response.statusText === 'Unauthorized') {
                              return 'Unauthorized';
                         }
                         else {
                              return response.json();
                         }
                    }).then(function (result) {
                         console.log(result);
                         if (result === 'Unauthorized') {
                              setLoading(false);
                              Timer(7000, 'Kindly check your username and password.');
                         }
                         else {
                              if (result.data.active) {
                                   setLoading(false);
                                   saveCookies(JSON.stringify({ status: result.success, userID: result.data._id, email: result.data.email, admin: true, adminLevel: result.data.user_level }));
                              }
                              else {
                                   setLoading(false);
                                   Timer(7000, 'Your account is deactive. Kindly contact support.');
                              }
                         }
                    }).catch(function (params) {
                         console.log(params);
                         setLoading(false);
                         Timer(7000, ' ' + params);
                    });
          }
     }


     return <div style={{ backgroundColor: 'white', height: '100%', width: '100%', position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Alert style={{ margin: "10px", position: "absolute", right: "0px", top: "0px" }} show={visibility} variant="danger">
               <Alert.Heading style={{ fontSize: "16px" }}>{alertMessage}</Alert.Heading>
          </Alert>
          <Card style={{ width: '40%', heigh: '50%', borderRadius: '5px', backgroundColor: 'rgb(253,253,253)', position: 'absolute' }}>
               <Card.Body>
                    <div style={{
                         display: 'flex', alignItems: 'center', justifyContent: 'center'
                    }}>
                         <img style={{
                              marginTop: "5px", marginBottom: "5px", height: "auto", width: "40%"
                         }} src={process.env.PUBLIC_URL + '/images/colored_logo.png'} alt="dashboard_image" /></div>
                    <Form>
                         <h5 style={{ textAlign: "center" }}>Vehicle Requisition Module</h5>
                         <Form.Group onChange={function (element) {
                              setEmail(element.target.value);
                         }} type="email" value={email} className="mb-3" controlId="formBasicEmail">
                              <Form.Label>Email address</Form.Label>
                              <Form.Control type="email" placeholder="Enter email" />
                         </Form.Group>
                         <Form.Group onChange={function (element) {
                              setPassword(element.target.value);
                         }} type="password" value={password} className="form-control" placeholder="Enter password" className="mb-3" controlId="formBasicPassword">
                              <Form.Label  >Password</Form.Label>
                              <Form.Control type="password" placeholder="Password" />
                         </Form.Group>
                         <CustomButton style={{ width: '100%' }} onClick={handleLogin} text={'Sign In'} loading={loading} />
                    </Form>
               </Card.Body>
          </Card>
     </div>;
}

export default SiteAdminLogin;
