import React from 'react';
import './Card.css';

const CustomCard = ({ children, style }) => {

     return (<div className={"Card"} style={{ ...style }}>
          {children}
     </div>);
}

export default CustomCard;