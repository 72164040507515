import React, { useState, useEffect, useRef } from 'react';
import CustomLabel from "../components/CustomLabel";
import CustomAlert from '../components/CustomAlert';
import CustomButton from '../components/CustomButton';
import { Form } from 'react-bootstrap';
import cookie from 'react-cookies';
import CustomCard from '../components/CustomCard';
import CustomVehicleDropDown from "../components/CustomVehicleDropDown";
import CustomDivider from '../components/CustomDivider';
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import CustomTextInput from '../components/CustomTextInput';


const ModifyBreakdownCMTScreen = props => {
     console.log(props);
     const reload = false;
     const [userInfo, setUserInfo] = useState({ username: '', mobile: '', email: '', password: '', userLevel: 2, name: '' });
     const [userDetails, setUserDetails] = useState(null);
     const [loading, setLoading] = useState(false);
     const [department, setDepartmentList] = useState([]);
     const [height, setHeight] = useState(0);
     const [selectedDepartment, setSelectedDepartment] = useState(null);
     const [vehicleList, setVehicleList] = useState([]);
     const [vehicles, setVehicles] = useState([]);
     const [remarks, setRemarks] = useState(props.editElement.remarks);

     const [alertInfo, setAlertInfo] = useState({ visibility: false, type: 'danger', message: '' });

     function Timer(time, message, type) {
          setAlertInfo({ visibility: true, type: type, message: message });
          setInterval(() => {
               setAlertInfo({ visibility: false, type: type, message: '' });
          }, time);
     }

     function handleOrder(e) {
          if (e) { // add?
               e.preventDefault();
               //      e.persist();
          }
          const vehicle = [];
          if (vehicleList.length != 1) {
               Timer(5000, 'Kindly select vehicles for all items.', false);
          }
          else {
               for (let index = 0; index < vehicleList.length; index++) {
                    const element = vehicleList[index];
                    if (element.selectedData.value == 'na') {
                         vehicle.push(
                              {
                                   start: new Date().toString().split(' GMT')[0],
                                   end: element.requiredData.end,
                                   vehicleCategoryName: element.requiredData.vehicleCategoryName,
                                   vehicleCategoryValue: element.requiredData.vehicleCategoryValue,
                                   isVehicle: false,
                              });
                    }
                    else {
                         vehicle.push(
                              {
                                   start: new Date().toString().split(' GMT')[0],
                                   end: element.requiredData.end,
                                   vehicleCategoryName: element.requiredData.vehicleCategoryName,
                                   vehicleCategoryValue: element.requiredData.vehicleCategoryValue,
                                   vehicleNumber: element.selectedData.label,
                                   vehicleID: element.selectedData.value,
                                   isVehicle: true,
                              });
                    }
               }
               setLoading(true);
               const requestOptions = {
                    mode: 'cors',
                    method: 'POST',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Origin': 'http://smfal.speedotrack.com/' },
                    body: new URLSearchParams({ vehicles: JSON.stringify(vehicle), start: new Date().toString().split(' GMT')[0], orderID: props.editElement.id, vID: props.editElement.vehicleList._id, orderStatus: userInfo.userLevel, userID: (cookie.load('UserID', { doNotParse: true }) ?? '') })
               };
               fetch('http://smfal.speedotrack.com/api/update-order-replacement', requestOptions)
                    .then(function (response) {
                         return response.json();
                    }).then(function (result) {
                         console.log(result);
                         if (result.success === true) {
                              setLoading(false);
                              Timer(7000, `Order approved. User will be notified.`, result.success);
                              props.onComplete();
                         }
                         else {
                              setLoading(false);
                              Timer(7000, result.message, result.success);
                         }
                    }).catch(function (params) {
                         setLoading(false);
                         console.log(params);
                         Timer(7000, ' ' + params, false);
                    });
          }
     }

     function fetchAllDepartment() {
          const requestOptions = {
               mode: 'cors',
               method: 'POST',
               headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Origin': 'http://smfal.speedotrack.com/' },
               body: new URLSearchParams({ userID: (cookie.load('UserID', { doNotParse: true }) ?? '') })
          };
          fetch('http://smfal.speedotrack.com/api/department', requestOptions)
               .then(function (response) {
                    if (response.statusText === 'Unauthorized') {
                         return 'Unauthorized';
                    }
                    else {
                         return response.json();
                    }
               }).then(function (result) {

                    const deviceModelList = [];
                    result.forEach(element => {
                         deviceModelList.push({ label: element.name, value: element._id });
                    });
                    setDepartmentList(deviceModelList);
               }).catch(function (params) {
                    setDepartmentList([]);
                    Timer(7000, ' ' + params, false);
               });
     }

     function fetchUserDetails() {
          const requestOptions = {
               mode: 'cors',
               method: 'POST',
               headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Origin': 'http://smfal.speedotrack.com/' },
               body: new URLSearchParams({ orderUserID: props.editElement.user_id })
          };
          fetch('http://smfal.speedotrack.com/api/user-details', requestOptions)
               .then(function (response) {
                    if (response.statusText === 'Unauthorized') {
                         return 'Unauthorized';
                    }
                    else {
                         return response.json();
                    }
               }).then(function (result) {
                    setUserDetails(result);
               }).catch(function (params) {

                    Timer(7000, ' ' + params, false);
               });
     }

     function handleRemarks(e) {
          if (e) { // add?
               e.preventDefault();
               //      e.persist();
          }
          if (remarks == props.editElement.remarks) {
               Timer(5000, 'Cannot sent remarks. Kindly verify!', false);
          }
          else {
               const requestOptions = {
                    mode: 'cors',
                    method: 'POST',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Origin': 'http://smfal.speedotrack.com/' },
                    body: new URLSearchParams({
                         orderID: props.editElement.id,
                         remarks: remarks, orderStatus: '4', userID: (cookie.load('UserID', { doNotParse: true }) ?? '')
                    })
               };
               fetch('http://smfal.speedotrack.com/api/update-order-remarks', requestOptions)
                    .then(function (response) {
                         return response.json();
                    }).then(function (result) {
                         if (result.success === true) {
                              Timer(7000, `Review sent to client.`, result.success);
                         }
                         else {
                              Timer(7000, result.message, result.success);
                         }
                    }).catch(function (params) {
                         Timer(7000, ' ' + params, false);
                    });
          }
     }

     const fetchQuantity = (nos) => {
          let list = [];
          list.push({ ...props.editElement.vehicleList, id: 0 });
          setVehicles(list);
     }

     useEffect(() => {
          setHeight(myRef.current.clientWidth * 0.235);
          fetchUserDetails();
          fetchAllDepartment();
          fetchQuantity(props.editElement.vehicleList.quantity);
     }, [reload]);

     const myRef = useRef();


     return <div ref={myRef} style={{ width: '100%', height: '100%', backgroundColor: '#F4F5F7', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CustomCard style={{ width: '95%' }}>
               <div style={{ width: '100%', justifyContent: 'space-between', display: 'flex' }}>
                    <CustomLabel text={`Order ID - ${props.editElement.sID}`} />
                    <CustomLabel text={`Order Date - ${props.editElement.date}`} />
               </div>
               <div style={{ width: '100%', justifyContent: 'space-between', display: 'flex' }}>
                    <CustomLabel text={`Client Name - ${userDetails ? userDetails.name : ''}`} />
                    <CustomLabel text={`Client Department - ${userDetails ? userDetails.department : ''}`} />
               </div>
               <CustomDivider />
               <div style={{ marginTop: 5, paddingLeft: 5, width: '100%', justifyContent: 'space-between', display: 'flex', backgroundColor: 'silver' }}>
                    <label style={{ flex: 2, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>Vehicle Req.</label>
                    <label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>Assign</label>
               </div>
               <div style={{ overflow: 'auto', height: `${height}px` }}>
                    {vehicles.map((element) => {
                         return <div style={{ paddingLeft: 5, width: '100%', justifyContent: 'space-between', display: 'flex', backgroundColor: 2 % 2 == 0 ? '#F4F5F7' : 'white' }}>
                              <label style={{ flex: 2, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}><div><label>{element.vehicleCategoryName}</label>
                                   <br />
                                   <div style={{ marginTop: 5, paddingLeft: 5, width: '100%', justifyContent: 'space-between', display: 'flex', backgroundColor: '#F6F6F6' }}>
                                        <label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>Start Time</label>
                                        <label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>End Time</label></div>
                                   <div style={{ paddingLeft: 5, width: '100%', justifyContent: 'space-between', display: 'flex', backgroundColor: 'white' }}>
                                        <label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>{element.start}</label>
                                        <label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>{element.end}</label></div>
                              </div>
                              </label>
                              <CustomVehicleDropDown currentVehicleList={vehicleList} label={`Select Vehicle`} handleChange={(selectedOption) => {
                                   if (selectedOption.value == 'na') {
                                        setUserInfo((latestUserInfo) => { return { ...latestUserInfo, userLevel: '2' } });
                                   }
                                   if (vehicleList.length > 0) {
                                        let entry = true;
                                        for (let index = 0; index < vehicleList.length; index++) {
                                             const item = vehicleList[index];
                                             if (item.requiredData.id == element.id) {
                                                  vehicleList.splice(index, 1);
                                             }
                                             if (item.selectedData.value == selectedOption.value) {
                                                  entry = false;
                                                  break;
                                             }
                                        }
                                        if (entry) {
                                             const data = { requiredData: element, selectedData: selectedOption };
                                             setVehicleList((currentList) => { return [...currentList, data] });
                                        }
                                        else {
                                             Timer(7000, ' This vehicle is already added. Kindly select different vehicle.', 'danger');
                                        }
                                   }
                                   else {
                                        const data = { requiredData: element, selectedData: selectedOption };
                                        setVehicleList((currentList) => { return [...currentList, data] });
                                   }
                              }} vehicleData={element} />
                         </div>;
                    })}
               </div>
               <div style={{
                    flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '55px', width: '100%',
               }} >
                    <div style={{ width: '80%' }} >
                         <CustomTextInput placeholder={'Enter Remarks'} value={remarks} onTextChange={setRemarks} inputType={'text'} />
                    </div>
                    <div style={{ width: '10px' }} />
                    <div style={{ width: '20%' }} >
                         <CustomButton style={{ width: '100%' }} onClick={handleRemarks} text={'Send remarks'} loading={false} />
                    </div>
                    <div style={{ width: '10px' }} />
                    <div style={{ width: '20%' }} >
                         <CustomButton style={{ width: '100%' }} onClick={handleOrder
                         } text={'Approve replacement'} loading={loading} />
                    </div>
               </div>
          </CustomCard>
          <CustomAlert info={alertInfo} />
     </div >
}

export default ModifyBreakdownCMTScreen;