import React, { useState } from "react";
import ModifyOrderCMTScreen from "./ModifyOrderCMTScreen";
import ViewAllOrdersCMTContentScreen from "./ViewAllOrdersCMTContentScreen";

const ViewAllOrdersCMTScreen = () => {
     const [alertInfo, setAlertInfo] = useState({ visibility: false, type: 'danger', message: '' });

     function Timer(time, message, type) {
          setAlertInfo({ visibility: true, type: type, message: message });
          setInterval(() => {
               setAlertInfo({ visibility: false, type: type, message: '' });
          }, time);
     }

     const [isEdit, setIsEdit] = useState(false);
     const [editElement, setEditElement] = useState(null);


     return (isEdit ? <ModifyOrderCMTScreen editElement={editElement} /> : <ViewAllOrdersCMTContentScreen onEdit={(element) => {
          setEditElement(element);
          setIsEdit(true);
     }} />);

}

export default ViewAllOrdersCMTScreen;