import React, { useState, useEffect, useRef } from 'react';
import CustomLabel from "../components/CustomLabel";
import CustomAlert from '../components/CustomAlert';
import CustomButton from '../components/CustomButton';
import { Form } from 'react-bootstrap';
import cookie from 'react-cookies';
import CustomCard from '../components/CustomCard';
import CustomVehicleDropDown from "../components/CustomVehicleDropDown";
import CustomDivider from '../components/CustomDivider';
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import CustomTextInput from '../components/CustomTextInput';


const ModifyBreakdownUserScreen = props => {
     console.log(props.editElement);

     const reload = false;
     const [userInfo, setUserInfo] = useState({ username: '', mobile: '', email: '', password: '', userLevel: 2, name: '' });
     const [userDetails, setUserDetails] = useState(null);
     const [loading, setLoading] = useState(false);
     const [department, setDepartmentList] = useState([]);
     const [height, setHeight] = useState(0);
     const [selectedDepartment, setSelectedDepartment] = useState(null);
     const [vehicleList, setVehicleList] = useState([]);
     const [vehicles, setVehicles] = useState([]);
     const [remarks, setRemarks] = useState(props.editElement.remarks);

     const [alertInfo, setAlertInfo] = useState({ visibility: false, type: 'danger', message: '' });

     function Timer(time, message, type) {
          setAlertInfo({ visibility: true, type: type, message: message });
          setInterval(() => {
               setAlertInfo({ visibility: false, type: type, message: '' });
          }, time);
     }

     function handleOrder(e) {
          if (e) { // add?
               e.preventDefault();
               //      e.persist();
          }
          const vehicle = [];
          if (vehicleList.length != props.editElement.vehicleList.quantity) {
               Timer(5000, 'Kindly select vehicles for all items.', false);
          }
          else {
               for (let index = 0; index < vehicleList.length; index++) {
                    const element = vehicleList[index];
                    if (element.selectedData.value == 'na') {
                         vehicle.push(
                              {
                                   start: element.requiredData.start,
                                   end: element.requiredData.end,
                                   vehicleCategoryName: element.requiredData.vehicleCategoryName,
                                   vehicleCategoryValue: element.requiredData.vehicleCategoryValue,
                                   isVehicle: false,
                              });
                    }
                    else {
                         vehicle.push(
                              {
                                   start: element.requiredData.start,
                                   end: element.requiredData.end,
                                   vehicleCategoryName: element.requiredData.vehicleCategoryName,
                                   vehicleCategoryValue: element.requiredData.vehicleCategoryValue,
                                   vehicleNumber: element.selectedData.label,
                                   vehicleID: element.selectedData.value,
                                   isVehicle: true,
                              });
                    }
               }
               setLoading(true);
               const requestOptions = {
                    mode: 'cors',
                    method: 'POST',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Origin': 'http://smfal.speedotrack.com/' },
                    body: new URLSearchParams({ vehicles: JSON.stringify(vehicle), orderID: props.editElement.id, vID: props.editElement.vehicleList._id, orderStatus: userInfo.userLevel, userID: (cookie.load('UserID', { doNotParse: true }) ?? '') })
               };
               fetch('http://smfal.speedotrack.com/api/update-order', requestOptions)
                    .then(function (response) {
                         return response.json();
                    }).then(function (result) {
                         console.log(result);
                         if (result.success === true) {
                              setLoading(false);
                              Timer(7000, `Order approved. User will be notified.`, result.success);
                         }
                         else {
                              setLoading(false);
                              Timer(7000, result.message, result.success);
                         }
                    }).catch(function (params) {
                         setLoading(false);
                         console.log(params);
                         Timer(7000, ' ' + params, false);
                    });
          }
     }

     function fetchAllDepartment() {
          const requestOptions = {
               mode: 'cors',
               method: 'POST',
               headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Origin': 'http://smfal.speedotrack.com/' },
               body: new URLSearchParams({ userID: (cookie.load('UserID', { doNotParse: true }) ?? '') })
          };
          fetch('http://smfal.speedotrack.com/api/department', requestOptions)
               .then(function (response) {
                    if (response.statusText === 'Unauthorized') {
                         return 'Unauthorized';
                    }
                    else {
                         return response.json();
                    }
               }).then(function (result) {

                    const deviceModelList = [];
                    result.forEach(element => {
                         deviceModelList.push({ label: element.name, value: element._id });
                    });
                    setDepartmentList(deviceModelList);
               }).catch(function (params) {
                    setDepartmentList([]);
                    Timer(7000, ' ' + params, false);
               });
     }

     function fetchUserDetails() {
          const requestOptions = {
               mode: 'cors',
               method: 'POST',
               headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Origin': 'http://smfal.speedotrack.com/' },
               body: new URLSearchParams({ orderUserID: props.editElement.user_id })
          };
          fetch('http://smfal.speedotrack.com/api/user-details', requestOptions)
               .then(function (response) {
                    if (response.statusText === 'Unauthorized') {
                         return 'Unauthorized';
                    }
                    else {
                         return response.json();
                    }
               }).then(function (result) {
                    setUserDetails(result);
               }).catch(function (params) {

                    Timer(7000, ' ' + params, false);
               });
     }

     function handleRemarks(e) {
          if (e) { // add?
               e.preventDefault();
               //      e.persist();
          }
          if (remarks == props.editElement.remarks) {
               Timer(5000, 'Cannot sent remarks. Kindly verify!', false);
          }
          else {
               console.log(props.editElement);
               const requestOptions = {
                    mode: 'cors',
                    method: 'POST',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Origin': 'http://smfal.speedotrack.com/' },
                    body: new URLSearchParams({
                         orderID: props.editElement.id, vID: props.editElement.vehicleList._id,
                         remarks: remarks, orderStatus: '5', userID: (cookie.load('UserID', { doNotParse: true }) ?? '')
                    })
               };
               fetch('http://smfal.speedotrack.com/api/update-order-replace', requestOptions)
                    .then(function (response) {
                         return response.json();
                    }).then(function (result) {
                         if (result.success === true) {
                              Timer(7000, `Review sent to CMT.`, result.success);
                         }
                         else {
                              Timer(7000, result.message, result.success);
                         }
                    }).catch(function (params) {
                         console.log(params);
                         Timer(7000, ' ' + params, false);
                    });
          }
     }

     const fetchQuantity = (nos) => {
          let list = [];
          for (let index = 0; index < nos; index++) {
               list.push({ ...props.editElement.vehicleList, id: index });
          }
          setVehicles(list);
     }

     useEffect(() => {
          setHeight(myRef.current.clientWidth * 0.1);
          fetchUserDetails();
          fetchAllDepartment();
          fetchQuantity(props.editElement.vehicleList.quantity);
     }, [reload]);

     const myRef = useRef();


     return <div ref={myRef} style={{ width: '100%', height: '100%', backgroundColor: '#F4F5F7', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CustomCard style={{ width: '70%', justifyContent: 'center',alignItems:'center' }}>
               <div style={{ width: '100%', justifyContent: 'space-between', display: 'flex' }}>
                    <CustomLabel text={`Order ID - ${props.editElement.sID}`} />
                    <CustomLabel text={`Order Date - ${props.editElement.date}`} />
               </div>
               <CustomDivider />
               <CustomLabel text={`Request Replacement`} />
               <div style={{ overflow: 'auto', height: `${height}px` }}>
                    <div style={{ paddingLeft: 5, width: '100%', justifyContent: 'space-between', display: 'flex', backgroundColor: 2 % 2 == 0 ? '#F4F5F7' : 'white' }}>
                         <label style={{ flex: 2, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}><div><label>{props.editElement.vehicleList.vehicleCategoryName}</label>
                              <br />
                              <div style={{ marginTop: 5, paddingLeft: 5, width: '100%', justifyContent: 'space-between', display: 'flex', backgroundColor: '#F6F6F6' }}>
                                   <label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>Start Time</label>
                                   <label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>End Time</label></div>
                              <div style={{ paddingLeft: 5, width: '100%', justifyContent: 'space-between', display: 'flex', backgroundColor: 'white' }}>
                                   <label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>{props.editElement.vehicleList.start}</label>
                                   <label style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', marginBottom: '5px' }}>{props.editElement.vehicleList.end}</label></div>
                         </div>
                         </label>
                    </div>
               </div>
               <div style={{
                    flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '55px', width: '100%',
               }} >
                    <div style={{ width: '80%' }} >
                         <CustomTextInput placeholder={'Enter Remarks'} value={remarks} onTextChange={setRemarks} inputType={'text'} />
                    </div>
                    <div style={{ width: '10px' }} />
                    <div style={{ width: '25%' }} >
                         <CustomButton style={{ width: '100%' }} onClick={handleRemarks} text={'Send for review'} loading={loading} />
                    </div>
               </div>
          </CustomCard>
          <CustomAlert info={alertInfo} />
     </div >
}

export default ModifyBreakdownUserScreen;