import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form } from 'react-bootstrap';
import cookie from 'react-cookies';

const CustomVehicleDropDown = ({ currentVehicleList, vehicleData, label, handleChange }) => {
     const [vehicleList, setVehicleList] = useState([]);
     const reload = false;

     function fetchAllDepartment() {

          const requestOptions = {
               mode: 'cors',
               method: 'POST',
               headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Origin': 'http://smfal.speedotrack.com/' },
               body: new URLSearchParams({ vehicleCatID: vehicleData.vehicleCategoryValue, userID: (cookie.load('UserID', { doNotParse: true }) ?? '') })
          };
          fetch('http://smfal.speedotrack.com/api/vehicles', requestOptions)
               .then(function (response) {
                    if (response.statusText === 'Unauthorized') {
                         return 'Unauthorized';
                    }
                    else {
                         return response.json();
                    }
               }).then(function (result) {
                    let list = [];

                    for (let index = 0; index < result.length; index++) {

                         const element = result[index];

                         let name = element.company + ' - ' + element.number;
                         let isAlreadyTaken = false;
                         for (let index = 0; index < currentVehicleList.length; index++) {

                              const item = currentVehicleList[index];
                              if (item.selectedData.value == element._id) {
                                   isAlreadyTaken = true;
                                   break;
                              }
                         }
                         if (!isAlreadyTaken) {

                              if (element.booking.length > 0) {
                                   let shouldAdd = false;
                                   for (let index = 0; index < element.booking.length; index++) {
                                        const item = element.booking[index];
                                        if (Date.parse(vehicleData.start) >= Date.parse(item.start) && Date.parse(item.end) <= Date.parse(vehicleData.start) && Date.parse(vehicleData.end) >= Date.parse(item.start) && Date.parse(item.end) <= Date.parse(vehicleData.end)) {
                                             shouldAdd = true;
                                        }
                                        else {
                                             shouldAdd = false;
                                             break;
                                        }
                                   }
                                   if (shouldAdd) {
                                        list.push({ value: element._id, categoryID: element.categoryID, label: name, booking: element.booking, imei: element.model });
                                   }
                              }
                              else {
                                   list.push({ value: element._id, categoryID: element.categoryID, label: name, booking: element.booking, imei: element.model });
                              }
                         }
                    }
                    if (list.length == 0) {
                         list.push({ value: 'na', label: 'No Vehicles.' });
                    }
                    setVehicleList(list);
               }).catch(function (params) {
                    setVehicleList([]);
               });
     }

     useEffect(() => {
          fetchAllDepartment();
     }, [currentVehicleList]);

     return (
          <div style={{ flex: 1, fontFamily: 'monospace', fontSize: '14px', color: 'black', textAlign: 'start', padding: 10 }}>
               <Select onChange={handleChange} options={vehicleList} style={{ overflow: 'scroll' }} placeholder={label} />
          </div>
     );
}

export default CustomVehicleDropDown;